import '@xyflow/react/dist/style.css';

import { useQuery } from '@tanstack/react-query';
import { ReactFlowProvider } from '@xyflow/react';
import { useRef, useState } from 'react';
import { IoPerson } from 'react-icons/io5';
import { MdOutlineWork } from 'react-icons/md';
import { RiFileList2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';

import { queryClient } from '@/App';
import { Button } from '@/components/atoms/Button';
import ChartBreadcrumbs from '@/components/atoms/ChartBreadcrumbs';
import LightTooltip from '@/components/atoms/LightTooltip';
import SimpleSelect from '@/components/atoms/SimpleSelect';
import { ChartDownloadPdfButton } from '@/components/molecules/ChartDownloadPdfButton';
import ChartSubtitle from '@/components/molecules/ChartSubtitle';
import MultipleReportsPopover from '@/components/molecules/MultipleReportsPopover';
import NewSideFilter from '@/components/molecules/NewSideFilter';
import OrganizationalChartWrapper from '@/components/molecules/OrganizationalChartWrapper';
import ReportsPopover from '@/components/molecules/ReportsPopover';
import ChartGraph from '@/components/organisms/ChartGraph';
import {
  RoleChartFilterOptionsSchema,
  RoleChartFiltersOptions,
} from '@/constants/ChartConstants';
import { filters } from '@/constants/filters/NewChartFilter';
import api from '@/services/apiSgft';
import { chartAtom } from '@/state/ChartFilter.atom';
import { chartMarginAtom } from '@/state/ChartMargin.atom';
import { openJobtitleCreateModalAtom } from '@/state/CreatePositionModal.atom';
import { openJobtitleEditModalAtom } from '@/state/EditPositionModal.atom';
import { hideEmployeesAtom } from '@/state/HideEmployeesChart.atom';
import { multipleReportsPopoverAtom } from '@/state/MultipleReportsPopover.atom';
import { reportsPopoverAtom } from '@/state/ReportsPopover.atom';
import { PositionChartDoubleClickArgs } from '@/types/PositionChartDoubleClickArgs';
import { cn } from '@/utils/cn';
import { downloadFile } from '@/utils/downloadFile';

const Chart = () => {
  const [hideEmployees, setHideEmployees] = useRecoilState(hideEmployeesAtom);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isReallocating, setIsReallocating] = useState(false);
  const [chartFilters, setChartFilters] = useRecoilState(chartAtom);
  const [reportType, setReportType] = useState<'GERAL' | 'DIRETO'>('DIRETO');
  const multipleReportsPopover = useRecoilValue(multipleReportsPopoverAtom);
  const reportsPopover = useRecoilValue(reportsPopoverAtom);
  const chartMargin = useRecoilValue(chartMarginAtom);
  const isOpenCreate = useRecoilValue(openJobtitleCreateModalAtom);
  const isOpenEdit = useRecoilValue(openJobtitleEditModalAtom);

  const fetchEmployeesData = async () => {
    try {
      const someFilterSelected =
        chartFilters.companies.length ||
        chartFilters.boards.length ||
        chartFilters.managements.length ||
        chartFilters.coordinations.length ||
        chartFilters.employees.length;
      const response = await api.get('/position/person-chart', {
        params: {
          reportType,
          companiesFilter: chartFilters?.companies,
          boardFilter: chartFilters?.boards,
          managementFilter: chartFilters?.managements,
          coordinationFilter: chartFilters?.coordinations,
          employeeFilter: chartFilters?.employees,
          positionFilter: someFilterSelected
            ? undefined
            : chartFilters?.positionFilter,
        },
      });
      return response.data;
    } catch (e: any) {
      const errorMessage =
        e.response?.data?.message || 'Erro ao carregar os dados';
      toast.error(errorMessage, {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const fetchRolesData = async () => {
    try {
      const someFilterSelected =
        chartFilters.companies.length ||
        chartFilters.boards.length ||
        chartFilters.managements.length ||
        chartFilters.coordinations.length ||
        chartFilters.employees.length;
      const response = await api.get('/position/organization-chart', {
        params: {
          reportType,
          companiesFilter: chartFilters?.companies,
          boardFilter: chartFilters?.boards,
          managementFilter: chartFilters?.managements,
          coordinationFilter: chartFilters?.coordinations,
          employeeFilter: chartFilters?.employees,
          positionFilter: someFilterSelected
            ? undefined
            : chartFilters?.positionFilter,
        },
      });
      return response.data;
    } catch (e: any) {
      const errorMessage =
        e.response?.data?.message || 'Erro ao carregar os dados';
      toast.error(errorMessage, {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const fetchFilters = async () => {
    const response = await api.get(`position/filter`, {
      params: {
        boardFilter: chartFilters?.boards,
        managementFilter: chartFilters?.managements,
        coordinationFilter: chartFilters?.coordinations,
        employeeFilter: chartFilters?.employees,
      },
    });
    const parsedData = RoleChartFilterOptionsSchema.parse(response.data);
    return parsedData;
  };

  const { data: employeesData, isLoading: employeeIsLoading } = useQuery(
    ['employees-chart', chartFilters, reportType],
    fetchEmployeesData,
    {
      retry: false,
    },
  );

  const { data: rolesData, isLoading: rolesIsLoading } = useQuery(
    ['roles-chart', chartFilters, reportType],
    fetchRolesData,
    {
      retry: false,
    },
  );

  const { data: filterOptions, isLoading: isLoadingFilters } =
    useQuery<RoleChartFiltersOptions>(
      ['chart-filters', chartFilters],
      fetchFilters,
    );

  const handleSelect = (data: PositionChartDoubleClickArgs) => {
    setChartFilters({
      boards: [],
      companies: [],
      coordinations: [],
      employees: [],
      managements: [],
      positionFilter: data.positionFilter,
    });
  };

  const handleReallocateAllEmployees = async () => {
    setIsReallocating(true);
    try {
      await api.post('/position/allocate-all-employees');
      toast.success('Alocações recalculadas com sucesso!', {
        theme: 'colored',
        toastId: 'success',
      });

      queryClient.invalidateQueries(['employees-chart']);
      queryClient.invalidateQueries(['roles-chart']);
    } catch {
      toast.error('Erro ao recalcular alocações', {
        theme: 'colored',
        toastId: 'error',
      });
    } finally {
      setIsReallocating(false);
    }
  };

  const ref = useRef<HTMLDivElement>(null);
  const handleDownloadDivergences = async () => {
    setIsDownloading(true);
    try {
      await downloadFile(
        '/extraction/position-divergences',
        'Divergencias_Organograma.xlsx',
      );
    } catch {
      toast.error('Erro ao baixar arquivo', {
        theme: 'colored',
        toastId: 'error',
      });
    }
    setIsDownloading(false);
  };

  return (
    <div className="relative flex justify-center">
      <div className="center fixed top-3">
        <LightTooltip title="Organograma">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => {
              setHideEmployees(false);
            }}
          >
            <IoPerson
              color={`${!hideEmployees ? '#193CB9' : '#C7C9CF'}`}
              className="h-[17px] w-[17px]"
            />
          </Button>
        </LightTooltip>
        <LightTooltip title="Estrutura Organizacional">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => {
              setHideEmployees(true);
            }}
          >
            <MdOutlineWork
              color={`${hideEmployees ? '#193CB9' : '#C7C9CF'}`}
              className="h-[17px] w-[17px]"
            />
          </Button>
        </LightTooltip>
      </div>
      <div className="fixed right-20 top-3 z-10 flex items-center">
        <div className="flex w-[11.5rem] items-center justify-between">
          <div className="text-graphie text-[12px] font-semibold text-[#193CB9]">
            Modo:
          </div>
          <SimpleSelect
            title={''}
            values={[
              { value: 'DIRETO', label: 'REPORTE DIRETO' },
              { value: 'GERAL', label: 'REPORTE GERAL' },
            ]}
            width="9rem"
            fontSize="10px"
            value={reportType}
            setState={(value) => setReportType(value as 'GERAL' | 'DIRETO')}
          />
        </div>
        <ChartSubtitle />
        <ChartDownloadPdfButton />
        <LightTooltip title={'Baixar relatório de divergências'}>
          <Button
            className={cn(
              'mx-2 flex h-9 w-9 items-center justify-center rounded-full bg-transparent p-2 hover:bg-gray-200/70',
              isDownloading && 'cursor-not-allowed ',
            )}
            variant={'secondary'}
            onClick={handleDownloadDivergences}
            isLoading={isDownloading}
          >
            <RiFileList2Line className="fill-gray-500 text-green" size={20} />
          </Button>
        </LightTooltip>
      </div>
      <OrganizationalChartWrapper
        isLoading={employeeIsLoading || rolesIsLoading}
        isError={false}
      >
        <NewSideFilter
          filters={filters}
          atom={chartAtom}
          options={filterOptions}
          isLoading={isLoadingFilters}
        />
        <div className="flex w-full">
          {multipleReportsPopover ? (
            <MultipleReportsPopover />
          ) : reportsPopover ? (
            <ReportsPopover />
          ) : (
            <ChartBreadcrumbs
              board={hideEmployees ? rolesData?.board : employeesData?.board}
              management={
                hideEmployees
                  ? rolesData?.management
                  : employeesData?.management
              }
              coordination={
                hideEmployees
                  ? rolesData?.coordination
                  : employeesData?.coordination
              }
            />
          )}
        </div>
        <ReactFlowProvider>
          <div
            style={{
              marginTop:
                multipleReportsPopover || reportsPopover
                  ? '-13.75rem'
                  : chartMargin === 'base'
                    ? '-4.75rem'
                    : chartMargin === 'small'
                      ? '-7rem'
                      : chartMargin === 'medium'
                        ? '-10rem'
                        : '-12rem',
              marginBottom: '-30rem',
            }}
            className={`h-full`}
            ref={ref}
          >
            <ChartGraph
              handleChange={handleSelect}
              data={hideEmployees ? rolesData : employeesData}
            />
          </div>
        </ReactFlowProvider>
      </OrganizationalChartWrapper>
      {(isOpenCreate || isOpenEdit) && (
        <Button
          className="fixed bottom-4 right-4 z-[150] h-7 w-40 rounded-full text-xs text-white"
          onClick={() => console.log('oie')}
        >
          Criar/Editar Cargo
        </Button>
      )}
      <Button
        className="fixed bottom-4 right-4 h-7 w-40 rounded-full text-xs text-white"
        onClick={handleReallocateAllEmployees}
        isLoading={isReallocating}
      >
        Recalcular Alocações
      </Button>
    </div>
  );
};

export default Chart;
