import { DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { ScaleCode } from '@/constants/ScaleCodeConstants';

import { PositionWithAllocation } from '../organisms/ChartGraph';
import BadgeAvatar from './BadgeAvatar';
import CloseButton from './CloseButton';
import FixedTextField from './FixedTextfield';

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  position: PositionWithAllocation;
  poleColor: string;
  bgColor: string;
  textColor: string;
}

export const EmployeeModal = ({
  isOpen,
  setIsOpen,
  position,
  poleColor,
  bgColor,
  textColor,
}: Props) => {
  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: '10px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
          overflowY: 'auto',
          height: 'auto',
          minHeight: '37rem',
          width: '100%',
          maxWidth: '27rem',
        },
      }}
      slotProps={{ backdrop: { sx: { background: 'rgba(0, 0, 0, 0.25)' } } }}
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <DialogTitle>
        <div className="flex h-auto flex-col items-center justify-between">
          <div className="flex w-[26rem] justify-end">
            <CloseButton
              onClick={() => setIsOpen(false)}
              className="my-[-0.5rem]"
            />
          </div>
          <BadgeAvatar
            name={position.employee?.name ?? '-'}
            regime={position.regime}
            poleColor={poleColor}
            bgColor={bgColor}
            active={true}
            textColor={textColor}
            size={50}
            fontSize={20}
            height={10}
          />
          <div className="mt-3 flex flex-col items-center justify-center font-graphie font-medium">
            <div className={`text-[13px] text-[${poleColor}] text-center`}>
              {position.employee?.name}
            </div>
            <div className="mb-2 text-center text-[12px] text-[#5A5A5A]">
              {position.name}
            </div>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <FixedTextField
          title="Nome Completo"
          text={position.employee?.name ?? '-'}
        />
        <FixedTextField title="Email" text={position.employee?.email ?? '-'} />
        <FixedTextField
          title="Chapa"
          text={position.employee?.employeeNumber ?? '-'}
        />
        <FixedTextField
          title="Empresa"
          text={position.employee?.company?.toUpperCase() || ''}
        />

        <FixedTextField title="Diretoria" text={position.board.name} />
        <FixedTextField
          title="Gerência"
          text={position.management.name || ''}
        />
        <FixedTextField
          title="Coordenação"
          text={position.coordination.name || ''}
        />
        <FixedTextField
          title="Local de Trabalho"
          text={position.workStation.name}
        />
        <FixedTextField
          title="Escala"
          text={
            position.regime === 'TURNO'
              ? (position?.employee?.scaleCode &&
                  ScaleCode[position.employee.scaleCode]) ||
                'Turno'
              : 'Administrativo'
          }
        />
      </DialogContent>
    </Dialog>
  );
};
