import { z } from 'zod';

import { StatusTrainingControl } from './TrainingConstants';

export const FormSchema = z.object({
  completionStatus: z.nativeEnum(StatusTrainingControl),
  registerDate: z.date().optional(),
  completionDate: z.date(),
  justification: z.string(),
});

export type EmployeeTrainingForm = z.infer<typeof FormSchema>;
