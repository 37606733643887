import { NewFilter } from '@/types/NewFilter';

export const filters: NewFilter[] = [
  {
    key: 'companies',
    name: 'Empresa',
    isMulti: true,
    isDisabled: () => false,
  },
  {
    key: 'boards',
    name: 'Diretoria',
    isMulti: false,
    isDisabled: () => false,
  },
  {
    key: 'managements',
    name: 'Gerência',
    isMulti: false,
    isDisabled: () => false,
  },
  {
    key: 'coordinations',
    name: 'Coordenação',
    isMulti: false,
    isDisabled: () => false,
  },
  {
    key: 'employees',
    name: 'Colaborador',
    isMulti: false,
    isDisabled: () => false,
  },
];
