const colors: Record<string, string> = {
  Matriz: 'border-[2.5px] border-[#193CB9]',
  Alagoas: 'border-[2.5px] border-[#001746]',
  'Tucano Sul': 'border-[2.5px] border-[#1FBCDC]',
  ADM: 'bg-[#A9C1FD]',
  TURNO: 'bg-[#D4D9FF]',
  'ADM-text': 'text-[#193CB9]',
  'TURNO-text': 'text-[#4319B9]',
  'Posição Intermediária': 'border-[2.5px] border-[#C4C4C4] border-dashed',
  'Posição vazia': 'border-[2.5px] border-[#C4C4C4]',
  'Divergência no número de colaboradores': 'border-[2.5px] border-[#F4503A]',
  'Terminal Aquaviário de Maceió (TAMAC)': 'border-[2.5px] border-[#236BFF]',
  TAMAC: 'border-[2.5px] border-[#236BFF]',
  'TAMAC EMPRESA': 'border-[2.5px] border-[#19B986]',
};

export const getColor = (text: string) => {
  if (Object.keys(colors).includes(text)) {
    return colors[text];
  }
  return 'bg-transparent';
};
