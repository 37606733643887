import { z } from 'zod';

const TeamStructureFilterOption = z
  .object({
    id: z
      .union([z.number(), z.string()])
      .transform((id) => String(id))
      .pipe(z.string()),
    name: z.string(),
  })
  .transform(({ id, name }) => ({
    value: id,
    label: name,
  }));

const TeamStructureFilterSingleOption = z.string().transform((value) => {
  return {
    value,
    label: value,
  };
});

export const TeamStructureFilterOptionsSchema = z.object({
  management: z.array(TeamStructureFilterOption).default([]),
  workplace: z.array(TeamStructureFilterOption).default([]),
  teamStructure: z.array(TeamStructureFilterOption).default([]),
  area: z.array(TeamStructureFilterOption).default([]),
  sector: z.array(TeamStructureFilterSingleOption).default([]),
  schedule: z.array(TeamStructureFilterSingleOption).default([]),
  employee: z.array(TeamStructureFilterOption).default([]),
});

export type TeamStructureFiltersOptions = z.infer<
  typeof TeamStructureFilterOptionsSchema
>;
