import Avatar from '../assets/icons/avatar.svg';
import Bell from '../assets/icons/bell.svg';
import Book from '../assets/icons/book.svg';
import Calendar from '../assets/icons/calendar.svg';
import FlowChart from '../assets/icons/flow-chart.svg';
import Gear from '../assets/icons/gear.svg';
import Home from '../assets/icons/home.svg';
import Role from '../assets/icons/role.svg';
import School from '../assets/icons/school.svg';
import SwitchButtons from '../assets/icons/switch-buttons.svg';
import { SideBarItems } from '../types/SideBarItems';

const sideBarItems: SideBarItems = {
  Home: {
    name: 'Home',
    path: '/home',
    show: true,
    icon: <img src={Home} alt="Ícone Home" className="mr-2 w-4 flex-none" />,
  },
  LoadPlan: {
    name: 'Plano de Cargas',
    show: false,
    subItems: [{ name: '', path: '' }],
    icon: (
      <img
        src={SwitchButtons}
        alt="Ícone Plano de Cargas"
        className="w-4 flex-none"
      />
    ),
  },
  workSchedule: {
    name: 'Escala',
    show: true,
    subItems: [
      {
        name: 'Planejamento de Escalas e Férias',
        path: '/home/planejamento-de-escalas-e-ferias',
      },
      {
        name: 'Colaboradores',
        path: '/home/colaboradores',
      },
      {
        name: 'Criação de estrutura',
        path: '/home/planejamento-de-escalas-e-ferias/cadastrar-estrutura-de-turma',
      },
      // TODO: DESCOMENTAR QUANDO SUBIR IDA EXTRA
      // {
      //   name: 'Idas Extras ao Polo',
      //   path: '/home/idas-extras',
      // },
      // {
      //   name: 'Solicitação de Realocação',
      //   path: '/home/realocacoes',
      // },
    ],
    icon: <img src={Calendar} alt="Ícone Escala" className="w-4 flex-none" />,
  },
  QualificationAndTraining: {
    name: 'Qualificação e Trein.',
    show: false,
    subItems: [{ name: '', path: '' }],
    icon: (
      <img
        src={Book}
        alt="Ícone Qualificação e Treinamento"
        className="w-4 flex-none"
      />
    ),
  },
  Workforce: {
    name: 'Força de Trabalho',
    show: false,
    subItems: [{ name: '', path: '' }],
    icon: (
      <img
        src={Avatar}
        alt="Ícone Força de Trabalho"
        className="w-4 flex-none"
      />
    ),
  },
  Trainings: {
    name: 'Treinamentos',
    show: true,
    subItems: [
      {
        name: 'Matriz de Treinamento',
        path: '/home/matriz-treinamento',
      },
      {
        name: 'Controle de Treinamento',
        path: '/home/controle-treinamentos',
      },
      {
        name: 'Fornecedores',
        path: '/home/visualizar-fornecedores',
      },
      {
        name: 'Ficheiros',
        path: '/home/ficheiros',
      },
    ],
    icon: (
      <img src={School} alt="Ícone Treinamentos" className="w-4 flex-none" />
    ),
  },
  roles: {
    name: 'Cargos, Funções e Requisitos',
    show: true,
    subItems: [
      {
        name: 'Cargos e Descrições',
        path: '/home/cargos-e-requisitos',
      },
      {
        name: 'Controle de Requisitos',
        path: '/home/controle-de-requisitos',
      },
    ],
    icon: <img src={Role} className="w-4 flex-none" />,
  },
  Pendencies: {
    name: 'Pendências',
    path: '/home/visualizar-pendencias',
    show: true,
    icon: (
      <img src={Bell} alt="Ícone Pendências" className="mr-2 w-4 flex-none" />
    ),
  },
  Organograma: {
    name: 'Estrutura e Organograma',
    show: false,
    subItems: [
      {
        name: 'Visualizar',
        path: 'home/visualizar-estrutura-organograma',
      },
      {
        name: 'Cadastro de Alocações Fixas',
        path: '/home/cadastro-alocacoes-fixas',
      },
    ],
    icon: (
      <img src={FlowChart} alt="Ícone Organograma" className="w-4 flex-none" />
    ),
  },
  Cadastros: {
    name: 'Cadastros',
    show: true,
    subItems: [
      {
        name: 'Colaboradores',
        path: '/home/colaboradores',
      },
      {
        name: 'Diretorias',
        path: '/home/cadastro-de-diretoria',
      },
    ],
    icon: <img src={Gear} alt="Ícone Engrenagem" className="w-4 flex-none" />,
  },
};

export default sideBarItems;
