import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { createTheme, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import router from './routes/root';
const TRACKING_ID = import.meta.env.VITE_GA_TRACKING_ID;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 4, // 24 hours
      refetchOnWindowFocus: false,
    },
  },
});

interface AppProps {
  instance: PublicClientApplication;
}

const App: React.FC<AppProps> = ({ instance }) => {
  const theme = createTheme({
    typography: {
      fontFamily: ['Graphie', 'sans-serif'].join(','),
    },
  });

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID, {
      gaOptions: {
        userId: instance.getActiveAccount()?.localAccountId,
      },
    });
  }, []);

  return (
    <MsalProvider instance={instance}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
          <ToastContainer />
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </ThemeProvider>
    </MsalProvider>
  );
};

export default App;
