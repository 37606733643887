import { z } from 'zod';

import {
  Modality,
  PartModality,
  StatusTrainingControl,
} from './TrainingConstants';

export const FormSchema = z.object({
  completionStatus: z.nativeEnum(StatusTrainingControl),
  modality: z.nativeEnum(Modality),
  partModality: z.nativeEnum(PartModality).optional(),
  registerDate: z.date().optional(),
  completionDate: z.date().optional(),
  justification: z.string().optional(),
});

export type TrainingControlForm = z.infer<typeof FormSchema>;

export interface UpdateTrainingControlParams {
  trainingModality?: string;
  registerDate?: string;
  presencialCompletionDate?: string;
  onlineCompletionDate?: string;
  presencialCompletionStatus?: string;
  onlineCompletionStatus?: string;
  justification?: string;
  expirationStatus?: string;
  presencialTrainingPlanningId?: number;
  onlineTrainingPlanningId?: number;
}

export const verifyFormStatus = (
  modality: Modality,
  partModality: PartModality | undefined,
  completionStatus: StatusTrainingControl,
  justification: string | undefined,
  registerDate: Date | undefined,
  completionDate: Date | undefined,
) => {
  if (modality === Modality.Semipresencial && !partModality) {
    return false;
  }
  if (!completionStatus) {
    return false;
  }
  switch (completionStatus) {
    case StatusTrainingControl.NaoAplicavel:
      return !!completionDate && !!registerDate && !!justification && modality;
    case StatusTrainingControl.Concluido:
      return !!completionDate && !!registerDate && modality;
    case StatusTrainingControl.Reprovado:
      return !!completionDate && !!registerDate && modality;
    case StatusTrainingControl.NaoRealizado:
      return !!completionDate && modality;
    case StatusTrainingControl.Convalidado:
      return !!completionDate && !!registerDate && !!justification && modality;
    case StatusTrainingControl.Invalidado:
      return !!completionDate && !!registerDate && !!justification && modality;
    case StatusTrainingControl.ConvocacaoRejeitada:
      return !!completionDate && !!registerDate && modality;
    default:
      return true;
  }
};
