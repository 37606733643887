import { QueryKey } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { queryClient } from '@/App';
import { columns } from '@/constants/tableColumns/EvidenceFilesColumns';
import { EvidenceFile } from '@/types/EvidenceFile';

import Table from '../organisms/Table';
import Modal from './Modal';
import Spinner from './Spinner';

interface ViewFilesProps {
  files: EvidenceFile[];
  onDeleteFunction: (id: number) => Promise<void>;
  deleteFileDescrption: string;
  hideDeleteButton?: boolean;
  queryKey?: QueryKey;
  isLoading?: boolean;
  filesCount?: number;
}

export const ViewFiles = ({
  files,
  onDeleteFunction,
  deleteFileDescrption,
  hideDeleteButton,
  queryKey,
  isLoading,
  filesCount,
}: ViewFilesProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!files || files.length === 0) {
      setIsOpen(false);
    }
  }, [files]);

  return (
    <div>
      <div className="flex items-center">
        <button
          type="button"
          className={`cursor-pointer border-none bg-transparent text-[12px] ${filesCount === 0 ? 'text-[#b1b1b1]' : 'text-[#1E40B7]'} underline outline-none`}
          onClick={async () => {
            if (queryKey) {
              await queryClient.fetchQuery({ queryKey });
            }
            setIsOpen(true);
          }}
          disabled={filesCount === 0}
        >
          {`VISUALIZAR (${filesCount || (files.length ?? 0)})`}
        </button>
      </div>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        className="max-h-[50vh] max-w-[45rem]"
        title="Arquivos Importados"
      >
        <div className="px-2 py-3">
          {isLoading ? (
            <Spinner />
          ) : (
            <Table
              columns={columns(
                onDeleteFunction,
                deleteFileDescrption,
                hideDeleteButton,
              )}
              data={files}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};
