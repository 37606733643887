import LightTooltip from './LightTooltip';

interface Props {
  title: string;
  value: string | number | null;
  width?: string;
  showTooltip?: boolean;
}

const TextComponent = ({ title, value, width, showTooltip }: Props) => {
  const widthClass = width ? `w-[${width}]` : 'w-full';

  return (
    <div>
      <div className={`${widthClass} text-[12px] text-[#4A4A4A]`}>{title}</div>
      {showTooltip ? (
        <LightTooltip title={value}>
          <p
            className={`${widthClass} flex h-8 items-center truncate rounded-[5px] border-[1px] border-[#d8d8d8] bg-[#f0f0f0] px-2 text-[12px]`}
          >
            {value}
          </p>
        </LightTooltip>
      ) : (
        <div
          className={`${widthClass} flex h-8 items-center overflow-hidden rounded-[5px] border-[1px] border-[#d8d8d8] bg-[#f0f0f0] px-2 text-[12px]`}
        >
          {value}
        </div>
      )}
    </div>
  );
};

export default TextComponent;
