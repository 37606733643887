import { Dialog, Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import api from '@/services/apiSgft';
import { JobTitle } from '@/types/JobTitle';

import CloseButton from '../atoms/CloseButton';
import { JobTitleColumns } from '../atoms/JobTitleColumns';
import Spinner from '../atoms/Spinner';
import SubtitleText from '../atoms/SubtitleText';
import Table from './Table';

interface Props {
  modalIsOpen: boolean;
  handleClose: () => void;
  jobTitle: JobTitle;
}

export default function JobTitleHistoryModal({
  jobTitle,
  modalIsOpen,
  handleClose,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/job-title/history', {
        params: {
          jobTitleId: jobTitle.id,
        },
      });
      setData(response.data);
    } catch (e: any) {
      if (e.response && e.response.status !== 404) {
        toast.error('Erro ao carregar os dados', {
          theme: 'colored',
          toastId: 'error',
        });
        throw e;
      } else setData([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Transition appear show={modalIsOpen}>
      <Dialog className="relative z-50" onClose={handleClose}>
        <div className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            className="w-full"
          >
            <Dialog.Panel className="max-h-[90vh] min-h-96 w-full flex-col justify-between rounded-lg bg-white ">
              <div className="w-full flex-col justify-between p-5">
                <div className="flex h-auto items-center justify-between">
                  <div className="flex justify-between">
                    <SubtitleText
                      className="text-[20px]"
                      subtitle={'Histórico de Versões'}
                    />
                  </div>
                  <CloseButton onClick={handleClose} className="mt-[-1rem]" />
                </div>
                <div className="custom-scrollbar mt-5 max-h-96 overflow-auto overflow-y-scroll text-[#555555]">
                  {isLoading && (
                    <div className="flex h-[10rem] w-full items-center">
                      <Spinner size={50} />
                    </div>
                  )}
                  {data.length > 0 ? (
                    <div className="mb-5">
                      <Table
                        columns={JobTitleColumns}
                        data={data}
                        trBodyClassname="h-24"
                      />
                    </div>
                  ) : (
                    <div className="flex justify-center p-10 font-graphie text-[13px] font-bold text-[#747474]">
                      Ainda não existe histórico de cargos
                    </div>
                  )}
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
