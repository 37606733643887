import React, { ReactNode, useEffect, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';

interface AccordionProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  children: ReactNode;
  className?: string;
  icon?: ReactNode;
  buttonClassName?: string;
  forceClose?: boolean;
  isDefaultOpen?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  className,
  buttonClassName,
  icon,
  forceClose = false,
  isDefaultOpen = false,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  const handleChange = () => {
    setIsOpen((old) => !old);
  };

  useEffect(() => {
    if (forceClose) {
      setIsOpen(false);
    }
  }, [forceClose]);

  return (
    <div className={`${className ?? ''}`} {...rest}>
      <button
        type="button"
        className={`
          flex 
          min-h-[40px]
          w-full items-center 
          gap-2 whitespace-nowrap rounded
          border-none py-2 
          pl-3 text-sm font-medium text-primary
          hover:bg-[#f5f5f5]
          ${buttonClassName ?? ''}
        `}
        onClick={handleChange}
      >
        {icon}
        {!forceClose && title}
        {!forceClose && (
          <FaChevronDown
            className={`ml-auto mr-2 fill-primary transition-all ${
              isOpen && 'rotate-180'
            }`}
          />
        )}
      </button>

      <section
        className={`${
          isOpen && !forceClose
            ? 'visible max-h-96 opacity-100'
            : 'invisible max-h-0 opacity-0'
        } overflow-hidden transition-all duration-500 ease-in-out `}
      >
        {children}
      </section>
    </div>
  );
};

export default Accordion;
