import { useInfiniteQuery } from '@tanstack/react-query';
import { HttpStatusCode, isAxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { UploadProcedureTrainingMatrixButton } from '@/components/atoms/UploadProcedureTrainingMatrixButton';
import Cards from '@/components/molecules/Cards';
import ExpandableSubtitle from '@/components/molecules/ExpandableSubtitle';
import { Option } from '@/types/Filter';

import { Button } from '../../components/atoms/Button';
import { ExtractTrainingMatrixButton } from '../../components/atoms/ExtractTraningMatrixButton';
import SideFilter from '../../components/molecules/SideFilter';
import JobTrainingMatrix from '../../components/organisms/JobTraningMatrix';
import TrainingModal from '../../components/organisms/TrainingModal';
import { filters } from '../../constants/filters/JobTrainingMatrixFilter';
import api from '../../services/apiSgft';
import {
  JobTrainingMatrixFilters,
  jobTrainingMatrixFiltersAtom,
} from '../../state/JobTraningMatrixFilter.atom';
import {
  Indicators,
  PendingMatrixChanges,
  ShortTraining,
  TrainingMatrix,
} from '../../types/Training';
import { userCanSaveTraining } from '../../utils/handleSavePermissions';

const TrainingPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterValues, setFilteredValues] = useRecoilState(
    jobTrainingMatrixFiltersAtom,
  );
  const [filteredData, setFilteredData] = useState(filterValues);
  const [indicators, setIndicators] = useState<Indicators>();
  const [matrixRefetch, setMatrixRefetch] = useState(false);
  const [isNewTrainingModalOpen, setIsNewTraininigModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [pendingMatrixChanges, setPendingMatrixChanges] =
    useState<PendingMatrixChanges>({});
  const [isJobTitleFilteringIndicator, setIsJobTitleFilteringIndicator] =
    useState(false);
  const [isTrainingFilteringIndicator, setIsTrainingFilteringIndicator] =
    useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const trainingType = searchParams.get('tipo');
    const location = searchParams.get('polo');
    const training = searchParams.get('treinamento');
    setFilteredValues((prev) => ({
      ...prev,
      trainingType: trainingType ? [trainingType] : [],
      location: location ? [location] : [],
      training: training ? [training] : [],
    }));
    setFilteredData((prev) => ({
      ...prev,
      trainingType: trainingType ? [trainingType] : [],
      location: location ? [location] : [],
      training: training ? [training] : [],
    }));
    searchParams.delete('tipo');
    searchParams.delete('polo');
    searchParams.delete('treinamento');
    setSearchParams(searchParams);
  }, []);
  const [filtersData, setFiltersData] = useRecoilState(
    jobTrainingMatrixFiltersAtom,
  );
  const resetFilters = useResetRecoilState(jobTrainingMatrixFiltersAtom);

  const [modifiedCheckboxesMap, setModifiedCheckboxesMap] = useState<
    Map<string, boolean>
  >(new Map());

  const toggleEditMode = () => {
    if (isEditMode && Object.keys(pendingMatrixChanges).length > 0) {
      handleSendMappingChanges();
    }
    setIsEditMode(!isEditMode);
  };

  const fetchTrainingMatrix = async ({
    pageParam = 1,
  }): Promise<{
    trainingMatrix: TrainingMatrix;
    totalResults: number;
    nextPage: number | null;
  }> => {
    try {
      const response = await api.get(
        `job-title-from-rm-training-link/matrix?size=20&page=${pageParam}`,
        {
          params: {
            jobTitles: filteredData.jobTitle,
            locations: filteredData.location,
            trainingType: filteredData.trainingType,
            management: filteredData.management,
            areaCoordination: filteredData.areaCoordination,
            workstation: filteredData.workstation,
            trainings: filteredData.training,
          },
        },
      );

      return response.data;
    } catch (error) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: `error-${'job-title-from-rm-training-link/matrix'}`,
      });
      throw error;
    }
  };

  const {
    data: trainingsMatrixData,
    isLoading: isLoadingTrainingsMatrix,
    isError: isErrorTrainingsMatrix,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['trainings-matrix', filteredData, matrixRefetch],
    fetchTrainingMatrix,
    {
      retry: false,
      getNextPageParam: (actualPage) => {
        return actualPage.nextPage;
      },
    },
  );

  const trainingsData = trainingsMatrixData?.pages
    .flatMap((page) => page.trainingMatrix)
    .reduce(
      (acc, curr) => {
        const validIndexes = curr.jobTitlesFromRM
          .map((jobTitleFromRM, index) =>
            jobTitleFromRM.areaCoordination !== null &&
            jobTitleFromRM.management !== null &&
            jobTitleFromRM.name !== null
              ? index
              : -1,
          )
          .filter((index) => index !== -1);

        curr.jobTitlesFromRM = validIndexes.map(
          (index) => curr.jobTitlesFromRM[index],
        );
        curr.matrix = validIndexes.map((index) => curr.matrix[index]);

        acc.jobTitlesFromRM.push(...curr.jobTitlesFromRM);
        const trainingSet = new Set<ShortTraining>([...curr.trainings]);
        acc.trainings = [...trainingSet];
        acc.matrix.push(...curr.matrix);

        return acc;
      },
      {
        jobTitlesFromRM: [],
        trainings: [],
        matrix: [],
      },
    );

  const isLoading = isLoadingTrainingsMatrix;
  const isError = isErrorTrainingsMatrix;

  async function fetchIndicators(filters: JobTrainingMatrixFilters) {
    try {
      const response = await api.get('trainings/indicators', {
        params: {
          locations: filters.location,
          trainings: filters.training,
          jobTitles: filters.jobTitle,
          trainingType: filters.trainingType,
          management: filters.management,
          areaCoordination: filters.areaCoordination,
          workstation: filters.workstation,
        },
      });
      setIndicators(response.data);
    } catch (e) {
      toast.error('Erro ao carregar indicadores', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleApplyFilter = (isReseting: boolean) => {
    if (isReseting)
      setFilteredData(() => ({
        trainingType: [],
        location: [],
        jobTitle: [],
        training: [],
        management: [],
        areaCoordination: [],
        workstation: [],
      }));
    else {
      setFilteredData(() => ({
        ...filterValues,
      }));
      fetchIndicators(filterValues);
    }
  };

  const handleMatrixRefetch = async () => {
    setMatrixRefetch((prevState) => !prevState);
  };

  const handleResetFilter = async () => {
    filters.forEach((filter) => {
      if (filter.asyncFn) {
        filter
          .asyncFn('', jobTrainingMatrixFiltersAtom)
          .then((option: Option[]) => {
            if (filter.key === 'trainingType') {
              setFiltersData((prevState) => ({
                ...prevState,
                trainingType: option.map((op) => op.value),
              }));
            }
          })
          .then(() => resetFilters());
      }
    });
  };

  const handleClickJobTitlesPendencies = async () => {
    if (indicators?.unmappedJobTitleFromRM) {
      if (isJobTitleFilteringIndicator) {
        setIsJobTitleFilteringIndicator(false);
        setFilteredData((prevFilters) => ({
          ...prevFilters,
          jobTitle: [],
        }));
      } else {
        setIsJobTitleFilteringIndicator(true);
        setIsTrainingFilteringIndicator(false);
        setFilteredData((prevFilters) => ({
          ...prevFilters,
          jobTitle: indicators?.unmappedJobTitleFromRM.map((el) => el.id),
          training: [],
        }));
      }
    }
  };

  const handleClickTrainingsPendencies = async () => {
    if (indicators?.unmappedTrainings) {
      if (isTrainingFilteringIndicator) {
        setIsTrainingFilteringIndicator(false);
        setFilteredData((prevFilters) => ({
          ...prevFilters,
          training: [],
        }));
      } else {
        setIsTrainingFilteringIndicator(true);
        setIsJobTitleFilteringIndicator(false);
        setFilteredData((prevFilters) => ({
          ...prevFilters,
          training: indicators?.unmappedTrainings.map((el) => el.trainingName),
          jobTitle: [],
        }));
      }
    }
  };

  const handleMappingChange = (
    trainingId: number,
    jobTitleFromRMId: number,
    location: string,
    checked: boolean,
  ) => {
    const key = `${jobTitleFromRMId}-${trainingId}`;
    setModifiedCheckboxesMap((prevState) => {
      const newMap = new Map(prevState);
      newMap.set(key, !prevState.get(key));
      return newMap;
    });

    setPendingMatrixChanges((prevState) => {
      // Ensure that the nested structure exists
      prevState[location] = prevState[location] || {};
      prevState[location][jobTitleFromRMId] =
        prevState[location][jobTitleFromRMId] || {};
      prevState[location][jobTitleFromRMId][trainingId] = prevState[location][
        jobTitleFromRMId
      ][trainingId] || {
        initialState: !checked,
      };

      // Check if the current state matches the initial state
      if (
        checked ===
        prevState[location][jobTitleFromRMId][trainingId].initialState
      ) {
        // Remove the specific trainingId entry
        delete prevState[location][jobTitleFromRMId][trainingId];

        // If the jobTitleFromRMId no longer has any trainingId entries, remove it
        if (Object.keys(prevState[location][jobTitleFromRMId]).length === 0) {
          delete prevState[location][jobTitleFromRMId];
        }

        // If the location no longer has any jobTitleFromRMId entries, remove it
        if (Object.keys(prevState[location]).length === 0) {
          delete prevState[location];
        }

        return prevState;
      }

      // If the state does not match the initial state, update the checked property
      prevState[location][jobTitleFromRMId][trainingId].checked = checked;

      // Return the updated state
      return prevState;
    });
  };

  const handleSendMappingChanges = async () => {
    const previousMap = new Map(modifiedCheckboxesMap);

    try {
      const changes = Object.entries(pendingMatrixChanges)[0][1];

      for (const jobTitleFromRMId in changes) {
        for (const trainingId in changes[jobTitleFromRMId]) {
          const newKey = `${jobTitleFromRMId}-${trainingId}`;
          const newValue = changes[jobTitleFromRMId][trainingId].checked;

          setModifiedCheckboxesMap((prevState) => {
            const newMap = new Map(prevState);
            newMap.set(newKey, newValue);
            return newMap;
          });
        }
      }

      const response = await api.post(
        '/job-title-from-rm-training-link',
        pendingMatrixChanges,
      );

      if (response.status !== 201) {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      if (isAxiosError(error)) {
        if (
          error.response?.status === HttpStatusCode.BadRequest &&
          Array.isArray(error.response.data.message)
        ) {
          const errors: {
            trainingId: number;
            jobTitleFromRMId: number;
            message: string;
          }[] = error.response.data.message;

          for (const mappingError of errors) {
            const { jobTitleFromRMId, trainingId, message } = mappingError;

            const key = `${jobTitleFromRMId}-${trainingId}`;
            setModifiedCheckboxesMap((prevState) => {
              const newMap = new Map(prevState);
              newMap.set(key, previousMap.get(key) || false);
              return newMap;
            });

            toast.error(`Erro ao salvar mapeamento: ${message}`, {
              theme: 'colored',
              toastId: `error-trainings-${key}`,
            });
          }
        } else {
          setModifiedCheckboxesMap(previousMap);
          toast.error(
            `Não foi possível salvar o mapeamento: ${error.response?.data.message}`,
            {
              theme: 'colored',
              toastId: `error-trainings`,
            },
          );
        }
      }

      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: `error-trainings`,
      });
      throw error;
    }

    setPendingMatrixChanges({});
  };

  const canSave: boolean | undefined = userCanSaveTraining();

  return (
    <div
      className="relative flex w-full flex-col items-start"
      style={{ height: 'calc(100% - 40px)' }}
    >
      <div className="absolute -top-10 right-20 flex items-center">
        <Button
          className="mx-5 h-7 rounded-[1rem] text-xs font-medium"
          onClick={() => setIsNewTraininigModalOpen(true)}
          disabled={!canSave}
        >
          Cadastrar Treinamento
        </Button>
        {trainingsData && trainingsData.trainings?.length > 0 && (
          <Button
            className="mx-5 h-7 rounded-[1rem] text-xs font-medium"
            onClick={toggleEditMode}
            disabled={
              !canSave &&
              !trainingsData.trainings.some((it) => it.isResponsible)
            }
          >
            {isEditMode ? 'Salvar' : 'Editar'} Matriz
          </Button>
        )}
        <ExtractTrainingMatrixButton filteredData={filteredData} />
        <UploadProcedureTrainingMatrixButton
          handleResetFilter={handleResetFilter}
          handleMatrixRefetch={handleMatrixRefetch}
        />
      </div>
      <SideFilter
        refetchOnChange
        filters={filters}
        atom={jobTrainingMatrixFiltersAtom}
        applyChanges={handleApplyFilter}
        disabled={
          filtersData.trainingType.length === 0 ||
          filtersData.location.length === 0
        }
      />
      <div className="w-[92vw] pt-4">
        {indicators &&
          indicators.totalTrainings !== undefined &&
          indicators.totalTrainings !== null && (
            <ExpandableSubtitle subtitle={'Indicadores'}>
              <Cards
                cards={[
                  {
                    value: indicators.totalTrainings,
                    status: 'green',
                    title: 'Treinamentos Cadastrados',
                    width: '16rem',
                  },
                  {
                    value: indicators.unmappedJobTitleFromRM.length,
                    status: 'red',
                    title: 'Funções não mapeadas',
                    onClick: handleClickJobTitlesPendencies,
                    width: '16rem',
                    borderColor: isJobTitleFilteringIndicator
                      ? 'red'
                      : undefined,
                  },
                  {
                    value: indicators.unmappedTrainings.length,
                    status: 'red',
                    title: 'Treinamentos não mapeados',
                    onClick: handleClickTrainingsPendencies,
                    width: '16rem',
                    borderColor: isTrainingFilteringIndicator
                      ? 'red'
                      : undefined,
                  },
                ]}
              />
            </ExpandableSubtitle>
          )}
      </div>
      <JobTrainingMatrix
        trainingsMatrixData={trainingsData}
        isLoading={isLoading}
        isError={isError}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        filteredData={filteredData}
        needsToRefetch={matrixRefetch}
        setNeedsToRefetch={setMatrixRefetch}
        handleMatrixRefetch={handleMatrixRefetch}
        isModalOpen={isNewTrainingModalOpen}
        setIsModalOpen={setIsNewTraininigModalOpen}
        canSave={canSave}
        isEditMode={isEditMode}
        modifiedCheckboxes={modifiedCheckboxesMap}
        onMappingChange={handleMappingChange}
      />
      <TrainingModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        matrixRefetch={handleMatrixRefetch}
      />
    </div>
  );
};

export default TrainingPage;
