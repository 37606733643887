export const ScaleCode: { [key: string]: string } = {
  '1': '12 horas diárias - 7 x 7 Dia',
  '28': '12 horas diárias - 7 x 7 ( HRA )',
  '29': '12 horas diárias - 7 x 7 Dia ( HRA )',
  '34': '12 horas diárias - 7 x 7 Dia/Noite 7/7 ( HRA )',
  '35': '12 horas diárias - 7 x 7 Dia/Noite 3/4 ( HRA )',
  '36': '12 horas diárias - 7 x 7 Dia/Noite 7/7',
  '37': '12 horas diárias - 7 x 7 Dia/Noite 3/4',
  '2': '12 horas diárias - 14 x 14 Dia',
  '31': '12 horas diárias - 14 x 14 Dia ( HRA )',
  '32': '12 horas diárias - 14 x 14 Dia/Noite 7/7 ( HRA )',
  '33': '12 horas diárias - 14 x 14 Dia/Noite 7/7',
};
