import { IconButton } from '@mui/material';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CgSpinner } from 'react-icons/cg';
import { MdOutlineFileDownload } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

import Checkbox from '@/components/atoms/Checkbox';
import LightTooltip from '@/components/atoms/LightTooltip';
import NewSideFilter from '@/components/molecules/NewSideFilter';
import { Label } from '@/components/ui/label';
import {
  EmployeesFilterOptionsSchema,
  EmployeesFiltersOptions,
} from '@/constants/EmployeeConstants';
import { filters } from '@/constants/filters/NewEmployeesFilter';
import { trackEvent } from '@/utils/trackEvent';

import EmptyIcon from '../../assets/icons/empty.svg';
import EmployeesWrapper from '../../components/molecules/EmployeesWrapper';
import SearchInput from '../../components/molecules/SearchInput';
import FirstAllocationModal from '../../components/organisms/FirstAllocationModal';
import InfiniteTable from '../../components/organisms/InfiniteTable';
import { columns } from '../../constants/tableColumns/EmployeesTableColumns';
import api from '../../services/apiSgft';
import { employeeFiltersAtom } from '../../state/EmployeeFilter.atom';
import { FirstAllocationAtom } from '../../state/FirstAllocation.atom';
import { EmployeeFormGroup } from '../../types/Employee';

const Employees = () => {
  const [filteredData, setFilteredData] = useRecoilState(employeeFiltersAtom);
  const [firstAllocation, setFirstAllocation] =
    useRecoilState(FirstAllocationAtom);
  const [isDownloadingSheet, setIsDownloadingSheet] = useState(false);
  const methods = useForm<EmployeeFormGroup>();
  const { register, watch } = methods;

  async function downloadSheet() {
    setIsDownloadingSheet(true);
    try {
      const response = await api.get(`/extraction/employees`, {
        params: {
          management: filteredData.management,
          teamStructure: filteredData.teamStructure,
          workStation: filteredData.workStation,
          role: filteredData.role,
          noAllocation: filteredData.noAllocation,
        },
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', 'Extração de Colaboradores - SGFT.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      trackEvent(
        'Download de informações dos colaboradores',
        'file_download',
        url,
      );
    } catch (e) {
      toast.error('Estrutura sem dados', {
        theme: 'colored',
        toastId: 'error',
      });
      trackEvent(
        'Download de informações dos colaboradores',
        'file_download',
        'Erro ao realizar o Download',
      );
      throw e;
    } finally {
      setIsDownloadingSheet(false);
    }
  }

  const fetchEmployees = async ({ pageParam = 1 }) => {
    try {
      const response = await api.get(`/employees`, {
        params: {
          size: 20,
          page: pageParam,
          management: filteredData.management,
          workStation: filteredData.workStation,
          teamStructure: filteredData.teamStructure,
          employee: filteredData.employee,
          role: filteredData.role,
          noAllocation: filteredData.noAllocation,
        },
      });
      return response.data;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(['all-employees', filteredData], fetchEmployees, {
    retry: false,
    getNextPageParam: (actualPage) => {
      return actualPage.nextPage;
    },
  });
  const employees = data?.pages.flatMap((page) => page.employees) ?? [];

  const handleInputChange = (name: string) => {
    setFilteredData((prev) => ({ ...prev, employee: name }));
  };

  const fetchFilterOptions = async () => {
    try {
      const response = await api.get('employees/filters', {
        params: {
          management: filteredData.management,
          workStation: filteredData.workStation,
          teamStructure: filteredData.teamStructure,
          role: filteredData.role,
          noAllocation: filteredData.noAllocation,
        },
      });
      const parsedData = EmployeesFilterOptionsSchema.parse(response.data);
      return parsedData;
    } catch (error) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw error;
    }
  };

  const { data: filterOptions, isLoading: isLoadingFilters } =
    useQuery<EmployeesFiltersOptions>(
      ['employees-filters', filteredData],
      fetchFilterOptions,
    );

  useEffect(() => {
    const subscription = watch(
      (value, { name, type }) =>
        name === 'name' &&
        type === 'change' &&
        typeof value?.name === 'string' &&
        handleInputChange(value?.name),
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div
      className="relative mt-4 flex w-full flex-col items-start bg-white"
      style={{ height: 'calc(100% - 56px)' }}
    >
      <div className="relative mt-4 flex h-[calc(100%-56px)] w-full flex-col items-start bg-white">
        <div className="absolute -top-[72px] right-[52px]">
          <LightTooltip title="Extrair as informações de todos os colaboradores">
            <IconButton onClick={downloadSheet} disabled={isDownloadingSheet}>
              {isDownloadingSheet ? (
                <CgSpinner
                  size={30}
                  color="#c1c1c1"
                  className="h-full animate-spin"
                />
              ) : (
                <MdOutlineFileDownload />
              )}
            </IconButton>
          </LightTooltip>
        </div>

        <FormProvider {...methods}>
          <form
            className="w-full p-4 pb-0"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="w-full">
              <SearchInput
                placeholder="Pesquisar por colaborador"
                autoFocus
                {...register('name')}
              />
            </div>
          </form>
        </FormProvider>

        <NewSideFilter
          filters={filters}
          atom={employeeFiltersAtom}
          options={filterOptions}
          isLoading={isLoadingFilters}
        >
          <div className="my-1 flex items-center gap-2">
            <Checkbox
              defaultChecked={false}
              onChange={() =>
                setFilteredData(() => ({
                  ...filteredData,
                  noAllocation: !filteredData.noAllocation,
                }))
              }
              checked={!!filteredData.noAllocation}
            />
            <Label className="text-xs font-normal text-white">
              Sem alocação SGFT
            </Label>
          </div>
        </NewSideFilter>
        <EmployeesWrapper isLoading={isLoading} isError={isError}>
          <div className="flex h-full w-full flex-col overflow-hidden rounded-md bg-white p-5">
            <div className="mb-2 flex gap-8">
              <div className="flex items-center gap-2">
                <div className="h-4 w-4 rounded-full bg-[#193CB9]" />
                <span>Dados RM</span>
              </div>
              <div className="flex items-center gap-2">
                <div className="h-4 w-4 rounded-full bg-[#1FBCDC]" />
                <span>Alocações SGFT</span>
              </div>
            </div>
            {employees?.length ? (
              <InfiniteTable
                hasNextPage={hasNextPage}
                data={employees}
                columns={columns}
                fetchNextPage={fetchNextPage}
                isFetchingNextPage={isFetchingNextPage}
                alignTextLeft={true}
                isFirstColumn={true}
                isEmployeeList={true}
              />
            ) : (
              <div className="flex h-full w-full flex-col items-center justify-center">
                <img src={EmptyIcon} className="w-40" />
                <div className="w-full text-center text-sm">
                  <span className="font-semibold text-blue-800">
                    Nenhum colaborador foi encontrado
                  </span>
                </div>
              </div>
            )}
          </div>
        </EmployeesWrapper>
        {firstAllocation.isOpen === 'open' && (
          <FirstAllocationModal
            handleClose={() =>
              setFirstAllocation({
                ...firstAllocation,
                isOpen: 'closed',
              })
            }
          />
        )}
      </div>
    </div>
  );
};

export default Employees;
