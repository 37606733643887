import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { PropsWithChildren, useState } from 'react';
import { MdExpandMore } from 'react-icons/md';
import { toast } from 'react-toastify';

import { CompanyTag } from '@/components/atoms/CompanyTag';
import SimpleMenu from '@/components/atoms/Menu';
import ModalConfirmation from '@/components/molecules/ConfirmationModal';
import RoleModal from '@/components/molecules/RoleModal';
import api from '@/services/apiSgft';
import { GroupedJobTitle } from '@/types/JobTitle';
import { userIsRhOrAdmin } from '@/utils/handleSavePermissions';

interface JobTitleAccordionProps extends PropsWithChildren {
  title: string;
  groupedJobTitle: GroupedJobTitle;
  defaultExpanded?: boolean;
  className?: string;
}

const JobTitleAccordion = ({
  title,
  groupedJobTitle,
  defaultExpanded,
  children,
}: JobTitleAccordionProps) => {
  const isRhOrAdmin = userIsRhOrAdmin();
  const uniqueCompanies = [
    ...new Map(
      groupedJobTitle.companies.map((company) => [company.id, company]),
    ).values(),
  ];
  const [isJobTitleModalOpen, setIsJobTitleModalOpen] = useState(false);
  const [
    isUpdateJobTitleConfirmationDialogOpen,
    setIsUpdateJobTitleConfirmationDialogOpen,
  ] = useState(false);
  const [isCreatingIssueToUpdateJobTitle, setIsCreatingIssueToUpdateJobTitle] =
    useState(false);
  const handleCreateIssueToUpdateJobTitle = async () => {
    setIsCreatingIssueToUpdateJobTitle(true);
    try {
      const response = await api.put(
        `/job-title/create-issue/update/${groupedJobTitle.id}`,
      );
      toast.success('Issue criada com sucesso', {
        theme: 'colored',
        toastId: 'success',
      });
      const { url } = response.data;
      window.open(url);
    } catch {
      toast.success('Erro ao criar a issue', {
        theme: 'colored',
        toastId: 'error',
      });
    }

    setIsCreatingIssueToUpdateJobTitle(false);
    setIsUpdateJobTitleConfirmationDialogOpen(false);
  };
  const handleEdit = () => {
    if (false) {
      // setIsJobTitleModalOpen(true);
    } else {
      setIsUpdateJobTitleConfirmationDialogOpen(true);
    }
  };
  const handleDeactivate = () => {
    if (isRhOrAdmin) {
      console.log('Deactivate');
    }
  };
  const options = [
    { name: 'Editar Cargo', onClick: handleEdit, disabled: false },
    { name: 'Desativar Cargo', onClick: handleDeactivate, disabled: true },
  ];

  return (
    <>
      <AccordionPrimitive.Root
        type="multiple"
        className="w-full rounded-[10px]"
        defaultValue={defaultExpanded ? ['1'] : []}
      >
        <AccordionPrimitive.Item
          value="1"
          className="w-full rounded-[10px] bg-white"
        >
          <AccordionPrimitive.Header className="relative w-full rounded-[10px] bg-white">
            <AccordionPrimitive.Trigger className="group flex w-full grow rounded-[10px] px-5 py-[18px]">
              <div className="flex w-full">
                <div className="flex items-center gap-3">
                  <h3 className="whitespace-nowrap text-left text-base font-medium text-[#4A4A4A]">
                    {title}
                  </h3>
                  <MdExpandMore className=" text-3xl text-primary transition-transform duration-300 ease-[cubic-bezier(0.87,_0,_0.13,_1)] group-data-[state=open]:rotate-180" />
                </div>

                <div className="ml-auto flex w-full items-center justify-end gap-4">
                  <div className="flex items-center gap-2">
                    {uniqueCompanies.map((company) => (
                      <CompanyTag companyName={company.name} key={company.id} />
                    ))}
                  </div>
                  <SimpleMenu
                    options={options}
                    upDotsButton
                    upDotsButtonColor="action"
                  />
                </div>
              </div>
            </AccordionPrimitive.Trigger>
          </AccordionPrimitive.Header>
          <AccordionPrimitive.Content className="overflow-hidden rounded-[10px] bg-white px-5 text-[15px] data-[state=closed]:animate-slideUp data-[state=open]:animate-slideDown">
            <section className="flex flex-col gap-2 pb-4 pt-[10px]">
              {children}
            </section>
          </AccordionPrimitive.Content>
        </AccordionPrimitive.Item>
      </AccordionPrimitive.Root>
      <RoleModal
        isOpen={isJobTitleModalOpen}
        onClose={() => setIsJobTitleModalOpen(false)}
        data={undefined}
        type={'edit'}
      />
      <ModalConfirmation
        isOpen={isUpdateJobTitleConfirmationDialogOpen}
        confirmAction={handleCreateIssueToUpdateJobTitle}
        title="Confirmação de Edição"
        onClose={() => setIsUpdateJobTitleConfirmationDialogOpen(false)}
        description={`Ao prosseguir com esta ação, você será redirecionado para o BPMS para editar o cargo ${groupedJobTitle.title}`}
        isLoading={isCreatingIssueToUpdateJobTitle}
      />
    </>
  );
};

export default JobTitleAccordion;
