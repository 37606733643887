import { z } from 'zod';

export enum EmployeeStatus {
  ACTIVE = 'Ativo',
  INSS = 'Af.Previdência',
  SUSPENDED = 'Contrato de Trabalho Suspenso',
  FIRED = 'Demitido',
  VACATION = 'Férias',
  MATERNITY_LEAVE = 'Licença Mater.',
}

const EmployeeSchema = z.object({
  id: z.number(),
  name: z.string(),
  employeeNumber: z.string(),
  admissionDate: z.string(),
  position: z.string(),
  address: z.string(),
  state: z.string(),
  neighborhood: z.string().nullable(),
  city: z.string(),
  street: z.string(),
  houseNumber: z.string().nullable(),
  addressDetails: z.string().nullable(),
  zipCode: z.string(),
  workStation: z.string(),
  management: z.string().nullable(),
  managementId: z.number().optional(),
  regime: z.string(),
  manager: z.string().nullable(),
  managerSGFT: z.string().optional(),
  structure: z.string().optional(),
  team: z.string().optional(),
  areaCoordinator: z.string().optional(),
  vacation_relief_worker: z.boolean().optional(),
  fire_warden: z.boolean().optional(),
  status: z.nativeEnum(EmployeeStatus),
  on_notice: z.boolean().optional(),
  structure_area: z.string().optional(),
  structure_sector: z.string().optional(),
  notAllocated: z.boolean().optional(),
  nextVacationStartDate: z.string().optional(),
  nextVacationEndDate: z.string().optional(),
});

export type Employee = z.infer<typeof EmployeeSchema>;

export const EmployeesSchema = z.object({
  employees: z.array(EmployeeSchema),
  count: z.number(),
  nextPage: z.number().optional(),
});

export type Employees = z.infer<typeof EmployeeSchema>;

const EmployeesFilterOption = z
  .object({
    id: z
      .union([z.number(), z.string()])
      .transform((id) => String(id))
      .pipe(z.string()),
    name: z.string(),
  })
  .transform(({ id, name }) => ({
    value: id,
    label: name,
  }));

const EmployeesFilterSingleOption = z.string().transform((value) => {
  return {
    value,
    label: value,
  };
});

export const EmployeesFilterOptionsSchema = z.object({
  management: z.array(EmployeesFilterSingleOption).default([]),
  workStation: z.array(EmployeesFilterOption).default([]),
  teamStructure: z.array(EmployeesFilterSingleOption).default([]),
  role: z.array(EmployeesFilterSingleOption).default([]),
});

export type EmployeesFiltersOptions = z.infer<
  typeof EmployeesFilterOptionsSchema
>;
