import { Dialog, Transition } from '@headlessui/react';

import { TrainingControl } from '@/types/TrainingControl';
import { getFirstAndLastName } from '@/utils/getFirstAndLastName';

import CloseButton from '../atoms/CloseButton';
import SubtitleText from '../atoms/SubtitleText';
import TextComponent from '../atoms/TextComponent';
import TrainingControlCard from '../molecules/TrainingControlCard';

interface Props {
  trainingControl: TrainingControl;
  handleClose: () => void;
  modalIsOpen: boolean;
}

export default function TrainingControlModal({
  trainingControl,
  handleClose,
  modalIsOpen,
}: Props) {
  return (
    <Transition appear show={true}>
      <Dialog
        className="relative z-50"
        onClose={handleClose}
        open={modalIsOpen}
      >
        <div className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="custom-scrollbar max-h-[100vh] w-[52rem] flex-col justify-between overflow-auto overflow-y-scroll rounded-lg bg-white">
              <div className="w-full flex-col justify-between p-5">
                <div className="flex h-auto items-center justify-between">
                  <div className="flex justify-between">
                    <SubtitleText
                      className="text-[20px]"
                      subtitle="Editar Controle de Treinamento"
                    />
                  </div>
                  <CloseButton onClick={handleClose} className="mt-[-1rem]" />
                </div>
                <div className="mt-5">
                  <div className="mb-5 grid w-full grid-cols-4 gap-5">
                    <TextComponent
                      title="Nome"
                      value={getFirstAndLastName(trainingControl.employee.name)}
                    />
                    <TextComponent
                      title="Chapa"
                      value={trainingControl.employee.employeeNumber}
                    />
                    <TextComponent
                      title="Cargo"
                      value={trainingControl.employee.role}
                    />
                    <TextComponent
                      title="Local de Trabalho"
                      value={trainingControl.employee.workStation}
                    />
                    <div className="col-span-2">
                      <TextComponent
                        title="Treinamento"
                        value={trainingControl.training.name}
                      />
                    </div>
                    <TextComponent
                      title="Gerência"
                      value={trainingControl.employee.management}
                    />
                    <TextComponent
                      title="Coordenação"
                      value={trainingControl.employee.areaCoordinator}
                    />
                  </div>
                  <div className="flex w-full flex-col">
                    <TrainingControlCard
                      trainingControl={trainingControl}
                      onAbort={handleClose}
                    />
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
