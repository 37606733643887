import React, { useState } from 'react';
import { HiOutlineFilter } from 'react-icons/hi';
import { MultiValue, SingleValue } from 'react-select';
import { RecoilState, useRecoilState, useResetRecoilState } from 'recoil';
import { twMerge } from 'tailwind-merge';

import { NewFilter } from '@/types/NewFilter';
import { trackEvent } from '@/utils/trackEvent';

import { cn } from '../../utils/cn';
import { Button } from '../atoms/Button';
import { DatePicker, DatePickerRange } from '../atoms/DatePicker';
import SelectInput from '../atoms/Select';

interface NewSideFilterProps {
  filters: NewFilter[];
  atom: RecoilState<any>;
  options: any;
  isLoading?: boolean;
  children?: React.ReactNode;
  floatButtonClassName?: string;
  hasCheckbox?: boolean;
}

type TempFilterValues = {
  [key: string]: string[];
};

const NewSideFilter: React.FC<NewSideFilterProps> = ({
  filters,
  atom,
  options,
  isLoading,
  children,
  floatButtonClassName,
}) => {
  const [filterValues, setFilterValues] = useRecoilState(atom);
  const useResetFilterValues = useResetRecoilState(atom);
  const [isOpen, setIsOpen] = useState(false);
  const [tempFilterValues, setTempFilterValues] = useState(filterValues);

  const handleOpenFilter = () => {
    setIsOpen((old) => !old);
  };

  const handleResetFilters = () => {
    useResetFilterValues();
    const defaultFilterValues = filters.reduce((acc, filter) => {
      acc[filter.key] = [];
      return acc;
    }, {} as TempFilterValues);
    setTempFilterValues(defaultFilterValues);
    setIsOpen(false);
  };

  const handleApplyFilters = () => {
    trackEvent(atom.key, 'filter_content', '');
    setFilterValues(tempFilterValues);
    setIsOpen(false);
  };

  const onSelect = (key: string, isMulti: boolean) => {
    return (selectedOptions: MultiValue<any> | SingleValue<any>) => {
      setTempFilterValues((prevFilters: any) => {
        if (isMulti) {
          return {
            ...prevFilters,
            [key]: (selectedOptions as MultiValue<any>).map(
              (option) => option.value,
            ),
          };
        } else {
          return { ...prevFilters, [key]: [selectedOptions.value] };
        }
      });
    };
  };

  return (
    <div
      className={`fixed top-0 h-screen w-64 bg-primary ${isOpen ? 'right-0' : '-right-64'} z-40 transition-all duration-500`}
    >
      <div className="relative h-full w-full px-4 py-6">
        <button
          type="button"
          className={twMerge(
            'absolute -left-8 top-16 rounded-lg bg-primary p-2 py-3 pr-4',
            floatButtonClassName,
          )}
          onClick={handleOpenFilter}
        >
          <HiOutlineFilter className="h-5 w-5" color="white" />
        </button>

        <div
          className={cn('flex h-full flex-col overflow-hidden', {
            hidden: !isOpen,
          })}
        >
          <h1 className="mb-8 text-2xl font-bold text-white">Filtros</h1>

          <div className="flex h-full flex-col overflow-hidden">
            <div className="custom-scrollbar flex h-full flex-col gap-2 overflow-y-scroll px-2">
              {children}
              {filters.map((filter) => {
                return (
                  <div key={filter.key} className="flex flex-col gap-1">
                    <h3 className="text-sm font-bold text-white">
                      {filter.name}
                    </h3>

                    {filter.key !== 'timeRange' ? (
                      <SelectInput
                        onChange={onSelect(filter.key, filter.isMulti)}
                        options={options?.[filter.key] || []}
                        value={tempFilterValues[filter.key]}
                        filtersData={tempFilterValues}
                        isDisabled={false}
                        isMulti={filter.isMulti}
                        isLoading={isLoading}
                      />
                    ) : (
                      <DatePicker
                        value={
                          tempFilterValues?.[filter.key] as DatePickerRange
                        }
                        onSelect={(val) => {
                          setTempFilterValues((prevFilters: any) => ({
                            ...prevFilters,
                            [filter.key]: {
                              startDate: val?.startDate,
                              endDate:
                                val?.startDate && !val?.endDate
                                  ? val.startDate
                                  : val?.endDate,
                            },
                          }));
                        }}
                        showClearButton={false}
                        showShortCuts={true}
                        className={{
                          p: 'text-white',
                          button: 'h-9 rounded-lg hover:ring-white/50',
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            <div className="mt-4 flex flex-col items-center gap-2">
              <Button
                variant={'secondary'}
                className="mt-9 w-full"
                onClick={handleApplyFilters}
              >
                Aplicar Filtros
              </Button>
              <Button
                variant={'link'}
                className="text-white underline"
                onClick={handleResetFilters}
              >
                Limpar filtros
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSideFilter;
