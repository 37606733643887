/* eslint-disable no-unmodified-loop-condition */
import React, { useMemo } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

import { weekDays } from '../../constants/weekDays';
import useElementWidth from '../../hooks/useElementWidth';
import { useVirtualTable } from '../../hooks/useVirtualTable';
import { TeamRegisterSchedule } from '../../types/TeamRegisterSchedule';
import { TeamCollaborator } from '../../types/ViewSchedule';
import ScheduleTeam from '../atoms/ScheduleTeam';

interface ScheduleProps {
  data: TeamCollaborator[] | TeamRegisterSchedule[];
  startDate: Date;
  endDate: Date;
  stickyHeader: boolean;
}

const columnWidth = 70;
const Schedule: React.FC<ScheduleProps> = ({
  data,
  startDate,
  endDate,
  stickyHeader,
}) => {
  const tableData = useMemo(
    () =>
      data.map((employee) => {
        return Object.values(employee.schedule).map((schedule) => schedule);
      }),
    [data],
  );
  const dateRange = useMemo(() => {
    const range = [];
    const current = new Date(startDate);
    while (current <= endDate) {
      range.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }
    return range;
  }, [startDate, endDate]);
  const { availableWidth, measuredRef } = useElementWidth();

  const { virtualized, tableRef } = useVirtualTable({
    data: tableData,
    headers: dateRange,
    columnWidth,
    visibleWidth: availableWidth,
    extraColumns: 5,
  });
  const tableWidth = {
    width: columnWidth * tableData[0].length + columnWidth / 1.25,
  };
  return (
    <div className="h-full overflow-x-hidden" ref={measuredRef}>
      <ScrollContainer
        hideScrollbars={false}
        vertical={false}
        className="custom-scrollbar rounded-md border-l-2 border-white"
        innerRef={tableRef}
      >
        <table style={tableWidth}>
          <thead
            className={`flex h-14 items-center justify-start overflow-x-hidden bg-blue-200 ${stickyHeader ? 'absolute' : 'sticky left-0'} top-0 duration-200`}
            style={{
              width: availableWidth - (availableWidth % columnWidth),
            }}
          >
            <tr className="flex">
              {virtualized.headers.map(({ value: date, key }) => (
                <th
                  key={key}
                  className="flex flex-col items-center text-xs text-blue-800"
                  style={{ width: columnWidth }}
                >
                  <p className="font-normal">{weekDays[date.getDay()]}</p>
                  <p className="font-semibold">
                    {`${date.getDate().toString().padStart(2, '0')}/${(
                      date.getMonth() + 1
                    )
                      .toString()
                      .padStart(2, '0')}/${date.getFullYear() - 2000}`}
                  </p>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {virtualized.data.map(({ value: employee, key }) => (
              <tr
                className={`sticky left-0 flex h-12 items-center justify-start duration-200 odd:bg-[#F1F3FA] ${stickyHeader ? 'first:mt-14' : ''}`}
                key={key}
                style={{
                  width: availableWidth - (availableWidth % columnWidth),
                }}
              >
                <span className="flex overflow-x-hidden">
                  {employee.map(({ value: schedule, key }, index) => {
                    const isFirstOfGroup =
                      employee[index - 1]?.value.value !== schedule.value;
                    const isLastOfGroup =
                      employee[index + 1]?.value.value !== schedule.value;
                    console.log(schedule);
                    return (
                      <td style={{ minWidth: columnWidth }} key={key}>
                        <ScheduleTeam
                          scheduleTeamName={schedule.value}
                          isFirstOfGroup={isFirstOfGroup}
                          isLastOfGroup={isLastOfGroup}
                          message={schedule.message}
                          newTeam={schedule.newTeam}
                          workShiftExtensionMessage={
                            schedule.workShiftExtensionMessage
                          }
                          onNoticeActivation={schedule.onNoticeActivation}
                          onNoticeActivationMessage={
                            schedule.onNoticeActivationMessage
                          }
                          trainingTimes={schedule.trainingTimes}
                        />
                      </td>
                    );
                  })}
                </span>
              </tr>
            ))}
          </tbody>
        </table>
      </ScrollContainer>
    </div>
  );
};

export default Schedule;
