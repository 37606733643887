/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { queryClient } from '@/App';
import { FormSchema } from '@/constants/formSchema/JobtitleFormSchema';
import api from '@/services/apiSgft';

import { Button } from '../atoms/Button';
import ControlledCheckbox from '../atoms/ControlledCheckbox';
import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import Modal from '../atoms/Modal';
import Spinner from '../atoms/Spinner';
import UncontrolledInputText from '../atoms/UncontrolledInputText';
import RolesPlans from './RolesPlans';

interface RoleModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: any;
  type: 'create' | 'edit';
}

const RoleModal: React.FC<RoleModalProps> = ({
  isOpen,
  onClose,
  data,
  type,
}) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  console.log(data);
  const methods = useForm<z.infer<typeof FormSchema>>({
    defaultValues: {},
  });

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setIsSubmiting(true);
    console.log(data);
    try {
      if (type === 'create') {
        await api.post('', {});
        toast.success('Novo cargo criado com sucesso!', {
          theme: 'colored',
          toastId: 'success',
        });
      } else if (type === 'edit') {
        await api.put(`/`, {});
        toast.success('Cargo editado editada com sucesso!', {
          theme: 'colored',
          toastId: 'success',
        });
      }
      closeAndClear();
    } catch (error: any) {
      let errorMessage = `Erro ao ${type === 'create' ? 'criar' : type === 'edit' ? 'editar' : 'excluir'} cargo, tente novamente mais tarde!`;
      if (error.response.status !== 500) {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage, {
        theme: 'colored',
        toastId: 'error',
      });
      throw error;
    } finally {
      setIsSubmiting(false);
    }
  };

  const closeAndClear = () => {
    methods.reset();
    onClose();
    queryClient.invalidateQueries(['']);
  };

  const getCompanies = async () => {
    try {
      const response = await api.get('/company/all');
      return response.data.map((el: { id: number; name: string }) => ({
        value: el.id,
        label: el.name,
      }));
    } catch (e) {
      toast.error('Erro ao carregar empresas!', {
        theme: 'colored',
        toastId: 'error',
      });
    }
  };

  const { data: companies, isLoading: isLoadingCompanies } = useQuery(
    ['get-all-companies'],
    getCompanies,
  );

  const getBoardsData = async (id: number) => {
    try {
      const response = await api.get(`/board/filter?companyFilter=${id}`);
      return response.data.map((el: { id: number; name: string }) => ({
        value: el.id,
        label: el.name,
      }));
    } catch (e) {
      toast.error('Erro ao carregar diretorias!', {
        theme: 'colored',
        toastId: 'error',
      });
    }
  };

  const companyWatch = methods.watch('company');

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeAndClear}
      title={`${type === 'create' ? 'Criar novo cargo' : 'Editar cargo'}`}
      className="w-[47rem] max-w-[85vw]"
    >
      <FormProvider {...methods}>
        <form
          className="space-between flex h-[90%] flex-col px-5 py-2"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {isLoadingCompanies ? (
            <div className="flex h-[19rem] w-full justify-center">
              <Spinner />
            </div>
          ) : (
            <div className="w-full">
              <div className="mb-4 flex w-full justify-between">
                <UncontrolledInputText
                  title="Título do cargo"
                  required
                  width="44rem"
                  {...methods.register('jobtitleName')}
                  upperCase
                />
              </div>
              <div className="mb-4 flex w-full justify-between">
                <ControlledSimpleSelect
                  title={'Empresa'}
                  required
                  control={methods.control}
                  name={`company`}
                  width="21.5rem"
                  values={companies}
                  multiple
                />
                <ControlledSimpleSelect
                  title={'CBO'}
                  required
                  control={methods.control}
                  name={'cbo'}
                  width="21.5rem"
                  values={[]}
                />
              </div>
              {companyWatch &&
                companyWatch.includes(
                  companies?.find(
                    (el: { value: number; label: string }) =>
                      el.label === 'ORIGEM ENERGIA',
                  ).value,
                ) && (
                  <div className="mb-4 flex w-full justify-between">
                    <ControlledSimpleSelect
                      title={'Diretoria - Origem Energia'}
                      required
                      control={methods.control}
                      name={`origemBoard`}
                      width="21.5rem"
                      values={[]}
                      multiple
                    />
                    <ControlledSimpleSelect
                      title={'Gerência - Origem Energia'}
                      required
                      control={methods.control}
                      name={'origemManagement'}
                      width="21.5rem"
                      values={[]}
                      multiple
                    />
                  </div>
                )}
              {companyWatch &&
                companyWatch.includes(
                  companies?.find(
                    (el: { value: number; label: string }) =>
                      el.label === 'TAMAC',
                  ).value,
                ) && (
                  <div className="mb-4 flex w-full justify-between">
                    <ControlledSimpleSelect
                      title={'Diretoria - TAMAC'}
                      required
                      control={methods.control}
                      name={`tamacBoard`}
                      width="21.5rem"
                      values={companies}
                      multiple
                    />
                    <ControlledSimpleSelect
                      title={'Gerência - TAMAC'}
                      required
                      control={methods.control}
                      name={'tamacManagement'}
                      width="21.5rem"
                      values={[]}
                      multiple
                    />
                  </div>
                )}
              <div className="mb-4 flex w-full justify-between">
                <ControlledSimpleSelect
                  title={'Local de Trabalho'}
                  required
                  control={methods.control}
                  name={`workstation`}
                  width="44rem"
                  values={[]}
                />
              </div>
              <div className="mb-4 flex w-full justify-between">
                <UncontrolledInputText
                  title="Resumo do cargo"
                  required
                  width="44rem"
                  {...methods.register('jobtitleDescription')}
                />
              </div>
              <div className="flex w-[90%] justify-between">
                <ControlledCheckbox
                  title={'Criar cargo somente para a Estrutura Organizacional'}
                  name="onlyCreate"
                  control={methods.control}
                />
                <ControlledCheckbox
                  title={'Enviar cargo para avaliação de GHE por QSMS'}
                  name="sendToEvaluation"
                  control={methods.control}
                />
              </div>
              <RolesPlans methods={methods} />
              <div className="my-2 mt-[1.5rem] flex w-full justify-between">
                <Button
                  type="button"
                  className="h-8 min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
                  onClick={closeAndClear}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  className=" h-8 min-w-36"
                  isLoading={isSubmiting}
                >
                  Salvar
                </Button>
              </div>
            </div>
          )}
        </form>
      </FormProvider>
    </Modal>
  );
};

export default RoleModal;
