import { zodResolver } from '@hookform/resolvers/zod';
import { DialogActions, Modal } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  EmployeeTrainingForm,
  FormSchema,
} from '@/constants/EmployeeTrainingConstants';
import {
  historyStatusOptions,
  StatusTrainingControl,
} from '@/constants/TrainingConstants';
import { TrainingControlHistory } from '@/types/TrainingControlHistory';
import { removeTimeZone } from '@/utils/formatDate';

import api from '../../services/apiSgft';
import { Button } from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import ControlledCalendar from '../atoms/ControlledCalendar';
import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import SubtitleText from '../atoms/SubtitleText';
import TextComponent from '../atoms/TextComponent';
import UncontrolledInputText from '../atoms/UncontrolledInputText';

interface EditTrainingModalProps {
  isOpen: boolean;
  onClose: () => void;
  row: TrainingControlHistory;
}

const EditTrainingModal: React.FC<EditTrainingModalProps> = ({
  isOpen,
  onClose,
  row,
}) => {
  const queryClient = useQueryClient();
  const methods = useForm<EmployeeTrainingForm>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      completionStatus: row.completionStatus as StatusTrainingControl,
      registerDate:
        row.registerDate === '-'
          ? undefined
          : removeTimeZone(new Date(row.registerDate)),
      completionDate:
        row.completionDate === '-'
          ? undefined
          : removeTimeZone(new Date(row.completionDate)),
      justification: '',
    },
  });

  const onSubmit = async (data: EmployeeTrainingForm) => {
    try {
      await api.patch(
        `training-control/training-control-history/${row.id}`,
        data,
      );
      toast.success(`Histórico editado com sucesso!`, {
        theme: 'colored',
        toastId: 'success',
      });
      queryClient.invalidateQueries({
        queryKey: ['training-control'],
      });
      queryClient.invalidateQueries({
        queryKey: ['employee-trainings'],
      });
    } catch (e: any) {
      const errorMessage =
        e?.response?.status === 400
          ? e?.response?.data?.message
          : 'Não foi possível atualizar o histórico!';
      toast.error(errorMessage, {
        theme: 'colored',
        toastId: 'error',
      });
    } finally {
      closeAndClear();
    }
  };

  const justificationWatch = methods.watch('justification');

  const shouldDisableSaveButton = !justificationWatch;

  const closeAndClear = () => {
    queryClient.invalidateQueries(['employee-trainings']);
    methods.reset();
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={closeAndClear}>
      <div className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4">
        <div className="max-h-[85vh] flex-col justify-between overflow-auto overflow-y-scroll rounded-lg bg-white scrollbar-thin scrollbar-thumb-[#D9D9D9] scrollbar-thumb-rounded-full">
          <div className="flex h-auto items-center justify-between px-4 pb-1 pt-3">
            <SubtitleText
              className="text-[18px]"
              subtitle={'Editar informações'}
            />
            <CloseButton onClick={closeAndClear} className="mt-[-0.5rem]" />
          </div>
          <FormProvider {...methods}>
            <form
              className="space-between flex h-[90%] flex-col px-5 py-2"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <div className="mb-4 flex w-full">
                <TextComponent title="Treinamento" value={row.training.name} />
              </div>
              <div className="mb-4 flex justify-between">
                <ControlledCalendar
                  title="Data de inscrição"
                  name="registerDate"
                  control={methods.control}
                  width="15rem"
                  actions={[]}
                />
                <ControlledCalendar
                  title="Data de conclusão"
                  required
                  name="completionDate"
                  control={methods.control}
                  width="15rem"
                  actions={[]}
                />
                <ControlledSimpleSelect
                  title="Situação de treinamento"
                  required
                  control={methods.control}
                  name="completionStatus"
                  width="15rem"
                  values={historyStatusOptions}
                />
              </div>
              <div className="flex justify-between">
                <UncontrolledInputText
                  required
                  title="Justificativa de alteração"
                  width="47rem"
                  {...methods.register('justification')}
                />
              </div>
              <DialogActions
                sx={{
                  width: 'full',
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingBottom: '25px',
                  marginX: '10px',
                  marginTop: '3rem',
                }}
              >
                <Button
                  type="button"
                  className="h-8 min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
                  onClick={closeAndClear}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  className=" h-8 min-w-36"
                  isLoading={methods.formState.isSubmitting}
                  disabled={shouldDisableSaveButton}
                >
                  Salvar
                </Button>
              </DialogActions>
            </form>
          </FormProvider>
        </div>
      </div>
    </Modal>
  );
};

export default EditTrainingModal;
