/* eslint-disable react-hooks/rules-of-hooks */
import { ColumnDef } from '@tanstack/react-table';
import { FaChevronDown } from 'react-icons/fa';

import TableData from '@/components/atoms/TableData';
import { cn } from '@/utils/cn';
import { getFirstAndLastName } from '@/utils/getFirstAndLastName';

import TableCellText from '../../components/atoms/TableCellText';

export const columns: (
  isOpen: boolean,
  handleToggleCard: () => void,
) => Array<
  ColumnDef<{
    id: number;
    name: string;
    employeeNumber: string;
    role: string;
    workStation: string;
    pole: string;
    management: string;
    coordination: string;
    board: string;
    seniorityLevel: string;
    requirementControlsCount: number;
    situation: string;
  }>
> = (isOpen: boolean, handleToggleCard: () => void) => [
  {
    accessorKey: 'chevron',
    accessorFn: (row) => row.name,
    header: 'Nome',
    meta: {
      headerClassName: 'bg-white text-start px-4',
    },
    cell: () => {
      return (
        <button
          onClick={handleToggleCard}
          className="sticky left-4 z-[100] flex w-[40px] items-center justify-center bg-white pt-[2px]"
        >
          <FaChevronDown
            className={`fill-primary transition-all ${isOpen && 'rotate-180'}`}
          />
        </button>
      );
    },
  },
  {
    accessorKey: 'name',
    accessorFn: (row) => row.name,
    header: 'Nome',
    meta: {
      headerClassName: 'bg-white text-start',
    },
    cell: (props) => {
      return (
        <div className={`flex w-full items-center justify-start`}>
          <TableCellText
            text={'Nome:'}
            align="start"
            textAlign="text-center"
            className="font-bold uppercase text-primary"
            width="5rem"
          />
          <TableData
            tableValue={getFirstAndLastName(props.getValue() as string)}
            className="flex h-[32px] w-36 items-center justify-start whitespace-nowrap text-[11px] font-semibold"
            title={props.getValue() as string}
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'employeeNumber',
    accessorFn: (row) => row.employeeNumber,
    header: 'Chapa',
    meta: {
      headerClassName: 'bg-white text-start',
    },
    cell: (props) => {
      return (
        <div className={`flex items-center justify-start`}>
          <TableCellText
            text={'Chapa:'}
            align="center"
            textAlign="text-center"
            className="font-bold uppercase text-primary"
            width="4rem"
          />
          <TableCellText
            text={props.getValue() as string}
            align="start"
            textAlign="text-left"
            width="5rem"
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'pole',
    accessorFn: (row) => row.pole,
    header: 'Polo',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="ml-5 mr-5 flex items-center justify-start">
        <TableCellText
          text={'Polo:'}
          align="center"
          textAlign="text-center"
          className="min-w-fit max-w-fit font-bold uppercase text-primary"
        />
        <TableData
          tableValue={props.getValue() as string}
          className="ml-1 flex h-[32px] w-24 items-center justify-start whitespace-nowrap text-[11px] uppercase"
          title={props.getValue() ? (props.getValue() as string) : 'N/A'}
        />
      </div>
    ),
  },
  {
    accessorKey: 'board',
    accessorFn: (row) => row.board,
    header: 'Diretoria',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => {
      return (
        <div className="mr-5 flex items-start">
          <TableCellText
            text={'Diretoria:'}
            align="center"
            textAlign="text-center"
            className="min-w-fit max-w-fit font-bold uppercase text-primary"
          />
          <TableCellText
            text={
              (props.getValue() as string) &&
              (props.getValue() as string) !== '-'
                ? (props.getValue() as string)
                : 'N/A'
            }
            align="start"
            width="12rem"
            textAlign="text-left"
            className="ml-1"
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'management',
    accessorFn: (row) => row.management,
    header: 'Gerência',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex items-start">
        <TableCellText
          text={'Gerência:'}
          align="center"
          textAlign="text-center"
          className="min-w-fit max-w-fit font-bold uppercase text-primary"
        />
        <TableCellText
          text={props.getValue() ? (props.getValue() as string) : 'N/A'}
          align="start"
          width="12rem"
          textAlign="text-left"
          className="ml-1"
        />
      </div>
    ),
  },
  {
    accessorKey: 'coordination',
    accessorFn: (row) => row.coordination,
    header: 'Coordenação',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex items-start">
        <TableCellText
          text={'Coordenação:'}
          align="center"
          textAlign="text-center"
          className="min-w-fit font-bold uppercase text-primary"
        />
        <TableCellText
          text={props.getValue() ? (props.getValue() as string) : 'N/A'}
          align="start"
          width="12rem"
          textAlign="text-left"
          className="ml-1"
        />
      </div>
    ),
  },
  {
    accessorKey: 'workStation',
    accessorFn: (row) => row.workStation,
    header: 'Local de Trabalho',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex items-start">
        <TableCellText
          text={'Local de Trabalho:'}
          align="center"
          textAlign="text-center"
          className="min-w-fit max-w-fit font-bold uppercase text-primary"
        />
        <TableCellText
          text={props.getValue() ? (props.getValue() as string) : 'N/A'}
          align="start"
          width="12rem"
          textAlign="text-left"
          className="ml-1"
        />
      </div>
    ),
  },
  {
    accessorKey: 'role',
    accessorFn: (row) => row.role,
    header: 'Função',
    meta: {
      headerClassName: 'text-center',
    },
    cell: (props) => (
      <div className="mr-5 flex items-start">
        <TableCellText
          text={'Função:'}
          align="center"
          textAlign="text-center"
          className="min-w-fit max-w-fit font-bold uppercase text-primary"
        />
        <TableCellText
          text={props.getValue() ? (props.getValue() as string) : 'N/A'}
          align="start"
          width="12rem"
          textAlign="text-left"
          className="ml-1"
        />
      </div>
    ),
  },
  {
    accessorKey: 'seniorityLevel',
    accessorFn: (row) => row.seniorityLevel,
    header: 'Nível',
    meta: {
      headerClassName: '!text-center',
    },
    cell: (props) => (
      <div className="mr-5 flex items-center">
        <TableCellText
          text={'Nível:'}
          align="center"
          textAlign="text-center"
          className="min-w-fit max-w-fit font-bold uppercase text-primary"
        />
        <TableCellText
          text={props.getValue() ? (props.getValue() as string) : 'N/A'}
          align="start"
          width="12rem"
          textAlign="text-left"
          className="ml-1"
        />
      </div>
    ),
  },
  {
    accessorKey: 'requirementControlsCount',
    accessorFn: (row) => row.requirementControlsCount,
    header: 'Total de requisitos',
    meta: {
      headerClassName: '!text-center',
    },
    cell: (props) => (
      <div className="m-auto flex min-w-2 items-center justify-start">
        <TableCellText
          text={'Total de requisitos:'}
          align="center"
          textAlign="text-center"
          className="min-w-fit font-bold uppercase text-primary"
        />
        <p className="ml-2 rounded bg-[#D1E6FF] px-1.5 py-1 font-bold text-primary">
          {String(props.getValue()).padStart(2, '0')}
        </p>
      </div>
    ),
  },
  {
    accessorKey: 'situation',
    accessorFn: (row) => row.situation,
    header: 'Situação geral',
    meta: {
      headerClassName: '!text-center',
    },
    cell: (props) => {
      const value = props.getValue() as string;
      return (
        <div className="m-auto ml-4 flex items-center justify-center">
          <TableCellText
            text={'Situação geral:'}
            align="center"
            textAlign="text-center"
            className="min-w-fit max-w-fit font-bold uppercase text-primary"
          />
          <TableData
            tableValue={value}
            className={cn(
              'ml-1 flex h-[32px] w-16 items-center justify-start whitespace-nowrap text-[11px] font-bold uppercase',
              value === 'Irregular' ? 'text-[#F4533E]' : ' text-[#10AE88]',
            )}
            title={value || 'N/A'}
          />
        </div>
      );
    },
  },
];
