/* eslint-disable @typescript-eslint/no-unused-vars */
import { useFieldArray, useFormContext } from 'react-hook-form';

import RolePlan from './RolePlan';

interface Props {
  methods: any;
}

const RolesPlans = ({ methods }: Props) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `roles`,
  });

  const addPlan = () => {
    append({
      level: '',
      functions: '',
      experiences: '',
      habilities: '',
      educationalLevel: '',
      scholarityLevel: '',
      tecnic: '',
      graduation: '',
      postGraduation: '',
      professionalLevel: '',
      registers: '',
      certifications: '',
      softwares: '',
      knowlege: '',
      trainings: '',
      otherCourses: '',
      otherLevel: '',
      languages: '',
      others: '',
    });
  };

  return (
    <div>
      <div className="mt-1 flex justify-end">
        <button
          type="button"
          className="p-2 text-[13px] text-[#193CB9] underline"
          onClick={addPlan}
        >
          + Adicionar um novo nível
        </button>
      </div>
      {fields.map((el, index) => {
        return (
          <RolePlan
            key={index}
            control={control}
            methods={methods}
            addPlan={addPlan}
            index={index}
          />
        );
      })}
    </div>
  );
};

export default RolesPlans;
