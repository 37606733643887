/* eslint-disable react/display-name */
import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { TrainingTypeEnum } from '@/constants/trainingTypeOptions';
import { TrainingMatrix } from '@/types/Training';

import Check from '../../assets/icons/check.svg';
import Checkbox from '../atoms/Checkbox';

interface TrainingMatrixCellProps {
  isEditMode: boolean;
  rowIndex: number;
  colIndex: number;
  jobTitleFromRMId: number;
  trainingId: number;
  isCritical: boolean;
  totalWorkload: number | null;
  validity: number | null;
  recyclingWorkload: number | null;
  trainingsMatrixData: TrainingMatrix;
  modifiedCheckboxes: Map<string, boolean>;
  canSave?: boolean;
  isResponsible?: boolean;
  trainingType: TrainingTypeEnum;
  onChangeCallback: (checked: boolean) => void;
  jobTitleFromRMGap: number;
}

const TrainingMatrixCell = ({
  isEditMode,
  rowIndex,
  colIndex,
  trainingId,
  totalWorkload,
  validity,
  recyclingWorkload,
  isCritical,
  jobTitleFromRMId,
  trainingsMatrixData,
  modifiedCheckboxes,
  canSave,
  isResponsible,
  trainingType,
  onChangeCallback,
  jobTitleFromRMGap,
}: TrainingMatrixCellProps) => {
  const checkboxRef = useRef(null);
  const jobTitleIndex = rowIndex - jobTitleFromRMGap;
  const trainingIndex = colIndex;

  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    const updateIsChecked = () => {
      if (
        trainingsMatrixData?.matrix &&
        trainingsMatrixData?.matrix.length > jobTitleIndex &&
        trainingsMatrixData?.matrix[jobTitleIndex]?.length > trainingIndex
      ) {
        if (modifiedCheckboxes.has(`${jobTitleFromRMId}-${trainingId}`)) {
          return (
            modifiedCheckboxes.get(`${jobTitleFromRMId}-${trainingId}`) || false
          );
        } else {
          return trainingsMatrixData?.matrix[jobTitleIndex][trainingIndex];
        }
      }

      return false;
    };

    setIsChecked(updateIsChecked());
  }, [
    modifiedCheckboxes,
    jobTitleFromRMId,
    trainingId,
    trainingsMatrixData?.matrix,
    jobTitleIndex,
    trainingIndex,
  ]);

  const handleCheckboxChange = async (event: ChangeEvent<HTMLInputElement>) => {
    return onChangeCallback(event.target?.checked);
  };

  if (rowIndex === 0) {
    if (trainingType === TrainingTypeEnum.REGULATORY) {
      return (
        <div className="flex items-center justify-center px-2 text-[13px] font-bold text-blue-800">
          <div>{`${validity || ''}`}</div>
        </div>
      );
    }
    return (
      <div className="flex items-center justify-center px-2 text-[13px] font-bold text-blue-800">
        <div>{`${isCritical ? 'SIM' : 'NÃO'}`}</div>
      </div>
    );
  } else if (rowIndex === 1) {
    return (
      <div className="flex items-center justify-center px-2 text-[13px] font-bold text-blue-800">
        <div>{`${totalWorkload || ''}`}</div>
      </div>
    );
  } else if (rowIndex === 2) {
    if (trainingType === TrainingTypeEnum.REGULATORY) {
      return (
        <div className="flex items-center justify-center px-2 text-[13px] font-bold text-blue-800">
          <div>{`${recyclingWorkload || ''}`}</div>
        </div>
      );
    }
    return <div></div>;
  } else if (rowIndex === 3 && trainingType === TrainingTypeEnum.REGULATORY) {
    return <div></div>;
  } else {
    return (
      <div className="flex justify-center">
        {isEditMode ? (
          <Checkbox
            checked={isChecked}
            defaultChecked={isChecked}
            ref={checkboxRef}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleCheckboxChange(e);
            }}
            disabled={!canSave && !isResponsible}
          />
        ) : (
          <div>
            {isChecked ? <img src={Check} className="w-8 flex-none" /> : <></>}
          </div>
        )}
      </div>
    );
  }
};

export default TrainingMatrixCell;
