import * as r from '@recoiljs/refine';
import { atom } from 'recoil';
import { urlSyncEffect } from 'recoil-sync';

export interface TrainingControlAtom extends Record<string, string[]> {
  employee: string[];
  role: string[];
  rmStatus: string[];
  training: string[];
  presencialCompletionStatus: string[];
  onlineCompletionStatus: string[];
  trainingPole: string[];
  expirationStatus: string[];
  management: string[];
  areaCoordinator: string[];
  trainingType: string[];
  pole: string[];
  workstation: string[];
}

const stringArrayChecker = r.array(r.string()) as r.Checker<string[]>;

export const trainingControlAtom = atom<TrainingControlAtom>({
  key: 'trainingControl',
  default: {
    employee: [],
    role: [],
    rmStatus: [],
    training: [],
    presencialCompletionStatus: [],
    onlineCompletionStatus: [],
    trainingPole: [],
    expirationStatus: [],
    management: [],
    areaCoordinator: [],
    trainingType: [],
    pole: [],
    workstation: [],
  },
  effects: [
    urlSyncEffect<TrainingControlAtom>({
      refine: r.object({
        employee: stringArrayChecker,
        role: stringArrayChecker,
        rmStatus: stringArrayChecker,
        training: stringArrayChecker,
        presencialCompletionStatus: stringArrayChecker,
        onlineCompletionStatus: stringArrayChecker,
        trainingPole: stringArrayChecker,
        expirationStatus: stringArrayChecker,
        management: stringArrayChecker,
        areaCoordinator: stringArrayChecker,
        trainingType: stringArrayChecker,
        pole: stringArrayChecker,
        workstation: stringArrayChecker,
      }),
      history: 'push',
    }),
  ],
});
