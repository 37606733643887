import * as r from '@recoiljs/refine';
import { atom } from 'recoil';
import { urlSyncEffect } from 'recoil-sync';

export interface ChartFilterAtom {
  companies: string[];
  boards: string[];
  managements: string[];
  coordinations: string[];
  employees: string[];
  positionFilter?: number;
}

const stringArrayChecker = r.array(r.string()) as r.Checker<string[]>;

export const chartAtom = atom<ChartFilterAtom>({
  key: 'chart',
  default: {
    companies: [],
    boards: [],
    managements: [],
    coordinations: [],
    employees: [],
    positionFilter: undefined,
  },
  effects: [
    urlSyncEffect<ChartFilterAtom>({
      refine: r.object({
        companies: stringArrayChecker,
        boards: stringArrayChecker,
        managements: stringArrayChecker,
        coordinations: stringArrayChecker,
        employees: stringArrayChecker,
        positionFilter: r.optional(r.number()),
      }),
      history: 'push',
    }),
  ],
});
