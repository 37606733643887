import { PopperPlacementType } from '@mui/material';
import {
  LocalizationProvider,
  PickersActionBarAction,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { ptBR } from '@mui/x-date-pickers/locales';
import br from 'date-fns/locale/pt-BR';
import { useState } from 'react';
import { Controller, FieldError } from 'react-hook-form';

interface Props extends DatePickerProps<any> {
  title: string;
  width?: string;
  name: string;
  control: any;
  setCalendar?: (value: boolean) => void;
  fieldError?: FieldError;
  errorMessage?: string;
  required?: boolean;
  placement?: PopperPlacementType;
  actions?: PickersActionBarAction[];
}

const ControlledCalendar = ({
  title,
  width,
  name,
  setCalendar,
  fieldError,
  errorMessage,
  required,
  placement,
  actions = ['clear'],
  ...rest
}: Props) => {
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  return (
    <div>
      <div className="font-graphie text-[12px] text-[#4A4A4A]">
        {title}
        {required && <span className="text-red">*</span>}
      </div>
      <Controller
        render={({ field: { onChange, value, ...controllerRest } }) => (
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            localeText={
              ptBR.components.MuiLocalizationProvider.defaultProps.localeText
            }
            adapterLocale={br}
          >
            <DatePicker
              className="date-input-lowercase font-graphie"
              {...rest}
              {...controllerRest}
              slotProps={{
                field: {
                  readOnly: true,
                },
                textField: {
                  placeholder: 'dd/mm/aaaa',
                },
                actionBar: {
                  actions,
                },
                popper: {
                  placement,
                },
              }}
              sx={{
                width: `${width || '27rem'}`,
                '& .MuiInputBase-root': {
                  height: '2rem',
                  fontSize: '12px',
                },
              }}
              defaultValue={rest.defaultValue}
              value={value ? new Date(value) : undefined}
              disabled={rest.disabled}
              onChange={(e, a) => {
                onChange(e, a);
                rest.onChange && rest.onChange(e, a);
              }}
              open={calendarIsOpen}
              onClose={() => {
                setCalendarIsOpen(false);
                setCalendar && setCalendar(false);
              }}
              onOpen={() => {
                setCalendarIsOpen(true);
                setCalendar && setCalendar(true);
              }}
            />
          </LocalizationProvider>
        )}
        name={name}
      />
      {fieldError && (
        <div className="text-[10px] text-[#dd0000]">{errorMessage}</div>
      )}
    </div>
  );
};

export default ControlledCalendar;
