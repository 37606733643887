import { NewFilter } from '@/types/NewFilter';

export const filters: NewFilter[] = [
  {
    key: 'timeRange',
    name: 'Período',
    isMulti: false,
    isDisabled: () => false,
  },
  {
    key: 'management',
    name: 'Gerência',
    isMulti: false,
    isDisabled: () => false,
  },
  {
    key: 'teamStructure',
    name: 'Estrutura de Turma',
    isMulti: true,
    isDisabled: () => false,
  },
  {
    key: 'area',
    name: 'Área',
    isMulti: true,
    isDisabled: () => false,
  },
  {
    key: 'sector',
    name: 'Setor',
    isMulti: true,
    isDisabled: () => false,
  },
  {
    key: 'workplace',
    name: 'Local de Trabalho',
    isMulti: true,
    isDisabled: () => false,
  },
  {
    key: 'schedule',
    name: 'Escala',
    isMulti: true,
    isDisabled: () => false,
  },
  {
    key: 'employee',
    name: 'Colaborador',
    isMulti: true,
    isDisabled: () => false,
  },
];
