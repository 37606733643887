import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import UncontrolledInputText from '../atoms/UncontrolledInputText';
import ExpandableSubtitle from './ExpandableSubtitle';

interface Props {
  control: any;
  methods: any;
  addPlan: () => void;
  index: number;
}

const RolePlan = ({ control, methods, index }: Props) => {
  const educationalRequirements = [
    'Requisitos Educacionais Obrigatórios',
    'Requisitos Educacionais Desejáveis',
  ];
  const professionalRequirements = [
    'Requisitos Profissionais Obrigatórios',
    'Requisitos Profissionais Desejáveis',
  ];
  const otherRequirements = [
    'Outros Requisitos Obrigatórios',
    'Outros Requisitos Desejáveis',
  ];

  return (
    <div className="flex w-full flex-col justify-between gap-2 py-2">
      <ExpandableSubtitle subtitle="Detalhamento do Cargo">
        <div className="flex w-[44rem] justify-between">
          <ControlledSimpleSelect
            title={'Nível do Cargo'}
            required
            control={control}
            name={`roles.${index}.level`}
            width="10.5rem"
            values={[]}
          />
          <UncontrolledInputText
            title="Funções e Responsabilidades"
            required
            width="10.5rem"
            {...methods.register(`roles.${index}.functions`)}
          />
          <UncontrolledInputText
            title="Experiências"
            required
            width="10.5rem"
            {...methods.register(`roles.${index}.experiences`)}
          />
          <UncontrolledInputText
            title="Habilidades e Competências"
            required
            width="10.5rem"
            {...methods.register(`roles.${index}.habilities`)}
          />
        </div>
      </ExpandableSubtitle>
      {educationalRequirements.map((el, idx) => {
        return (
          <ExpandableSubtitle key={idx} subtitle={el}>
            <div className="flex w-[44rem] justify-between">
              <UncontrolledInputText
                title="Nível"
                required
                width="8.5rem"
                {...methods.register(`roles.${index}.educationalLevel`)}
              />
              <ControlledSimpleSelect
                title={'Grau de Escolaridade'}
                required
                control={control}
                name={`roles.${index}.scholarityLevel`}
                width="8.5rem"
                values={[]}
                multiple
              />
              <ControlledSimpleSelect
                title={'Ensino Técnico'}
                required
                control={control}
                name={`roles.${index}.tecnic`}
                width="8.5rem"
                values={[]}
                multiple
              />
              <ControlledSimpleSelect
                title={'Graduação'}
                required
                control={control}
                name={`roles.${index}.graduation`}
                width="8.5rem"
                values={[]}
                multiple
              />
              <ControlledSimpleSelect
                title={'Pós-Graduação'}
                required
                control={control}
                name={`roles.${index}.postGraduation`}
                width="8.5rem"
                values={[]}
                multiple
              />
            </div>
          </ExpandableSubtitle>
        );
      })}
      {professionalRequirements.map((el, idx) => {
        return (
          <ExpandableSubtitle key={idx} subtitle={el}>
            <div className="custom-scrollbar flex w-full overflow-x-auto overflow-y-hidden">
              <div className="flex gap-2 pb-2">
                <UncontrolledInputText
                  title="Nível"
                  required
                  width="8.5rem"
                  {...methods.register(`roles.${index}.professionalLevel`)}
                />
                <ControlledSimpleSelect
                  title={'Registros Profissionais Ativos'}
                  required
                  control={control}
                  name={`roles.${index}.registers`}
                  width="8.5rem"
                  values={[]}
                  multiple
                  tooltipMessage="Registros Profissionais Ativos"
                  LabelClassName="w-[8rem] overflow-hidden text-ellipsis whitespace-nowrap"
                />
                <ControlledSimpleSelect
                  title={'Certificados'}
                  required
                  control={control}
                  name={`roles.${index}.certifications`}
                  width="8.5rem"
                  values={[]}
                  multiple
                />
                <ControlledSimpleSelect
                  title={'Softwares'}
                  required
                  control={control}
                  name={`roles.${index}.softwares`}
                  width="8.5rem"
                  values={[]}
                  multiple
                />
                <ControlledSimpleSelect
                  title={'Conhecimentos'}
                  required
                  control={control}
                  name={`roles.${index}.knowlege`}
                  width="8.5rem"
                  values={[]}
                  multiple
                />
                <ControlledSimpleSelect
                  title={'Treinamentos de Requisitos'}
                  required
                  control={control}
                  name={`roles.${index}.trainings`}
                  width="8.5rem"
                  values={[]}
                  multiple
                  tooltipMessage="Treinamentos de Requisitos"
                  LabelClassName="w-[8rem] overflow-hidden text-ellipsis whitespace-nowrap"
                />
                <ControlledSimpleSelect
                  title={'Outros Cursos'}
                  required
                  control={control}
                  name={`roles.${index}.otherCourses`}
                  width="8.5rem"
                  values={[]}
                  multiple
                />
              </div>
            </div>
          </ExpandableSubtitle>
        );
      })}
      {otherRequirements.map((el, idx) => {
        return (
          <ExpandableSubtitle key={idx} subtitle={el}>
            <div className="flex w-[26.5rem] justify-between">
              <UncontrolledInputText
                title="Nível"
                required
                width="8.5rem"
                {...methods.register(`roles.${index}.otherLevel`)}
              />
              <ControlledSimpleSelect
                title={'Idiomas'}
                required
                control={control}
                name={`roles.${index}.languages`}
                width="8.5rem"
                values={[]}
                multiple
              />
              <ControlledSimpleSelect
                title={'Outros'}
                required
                control={control}
                name={`roles.${index}.others`}
                width="8.5rem"
                values={[]}
                multiple
              />
            </div>
          </ExpandableSubtitle>
        );
      })}
    </div>
  );
};

export default RolePlan;
