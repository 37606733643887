import { cn } from '@/utils/cn';
interface CompanyTagProps {
  companyName: string;
}

export const CompanyTag = ({ companyName }: CompanyTagProps) => {
  return (
    <div
      className={cn(
        'mr-2 h-[2rem] min-w-[5rem] rounded p-2 text-[12px]',
        companyName === 'TAMAC'
          ? 'bg-[#83abd08a] text-[#222a84]'
          : 'bg-[#E9F1FF] text-[#1826BD]',
      )}
    >
      {`Empresa: ${companyName}`}
    </div>
  );
};
