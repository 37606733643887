import * as r from '@recoiljs/refine';
import { format } from 'date-fns';
import { atom } from 'recoil';
import { urlSyncEffect } from 'recoil-sync';

export interface ViewScheduleFiltersAtom {
  timeRange: {
    startDate: string | undefined;
    endDate: string | undefined;
  };
  workplace: string[];
  schedule: string[];
  sector: string[];
  area: string[];
  management: string[];
  employee: string[];
  teamStructure: string[];
}

const employee = JSON.parse(localStorage.getItem('employee') ?? '{}');
const managementId = employee?.management_id;
const startDate = new Date();
const endDate = new Date();
endDate.setMonth(startDate.getMonth() + 3);
const stringArrayChecker = r.array(r.string()) as r.Checker<string[]>;
// endDate.setDate(startDate.getDate() + 365);

export const viewScheduleFiltersAtom = atom<ViewScheduleFiltersAtom>({
  key: 'viewSchedule',
  default: {
    timeRange: {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    },
    workplace: [],
    sector: [],
    schedule: [],
    area: [],
    management: managementId ? [String(managementId)] : [],
    employee: [],
    teamStructure: [],
  },
  effects: [
    urlSyncEffect<ViewScheduleFiltersAtom>({
      refine: r.object({
        timeRange: r.object({
          startDate: r.string(),
          endDate: r.string(),
        }),
        workplace: stringArrayChecker,
        sector: stringArrayChecker,
        schedule: stringArrayChecker,
        area: stringArrayChecker,
        management: stringArrayChecker,
        employee: stringArrayChecker,
        teamStructure: stringArrayChecker,
      }),
      history: 'push',
    }),
  ],
});
