/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify';

import api from '@/services/apiSgft';
import { JobTitleAtom } from '@/state/JobTitle.atom';

import { Filter, Option } from '../../types/Filter';
import {
  JobTitleFilterOptionsSchema,
  JobTitleFiltersOptions,
} from '../RoleConstants';

const fetchFilter = async (
  field: keyof JobTitleFiltersOptions,
  search: string,
  atom?: JobTitleAtom,
) => {
  try {
    const response = await api.get('job-title/filters', {
      params: {
        // title: atom?.titles,
        seniorityLevels: atom?.seniorityLevels,
        companies: atom?.companies,
        boards: atom?.boards,
        managements: atom?.managements,
        workStations: atom?.workStations,
        // requirementType: atom?.requirementType,
        // educationalRequirements: atom?.educationalRequirements,
        // professionalRequirements: atom?.professionalRequirements,
        // otherRequirements: atom?.otherRequirements,
      },
    });
    const parsedFilters = JobTitleFilterOptionsSchema.parse(response.data);
    const fieldOptions = parsedFilters[field] || [];
    return fieldOptions;
  } catch (e) {
    toast.error('Erro ao carregar os dados', {
      theme: 'colored',
      toastId: 'error',
    });
    throw e;
  }
};

export const filters: Filter[] = [
  {
    key: 'seniorityLevels',
    name: 'Nível',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: JobTitleAtom) =>
      fetchFilter('seniorityLevels', search, atom),
    isDisabled: (_: JobTitleAtom) => false,
  },
  {
    key: 'companies',
    name: 'Empresa',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: JobTitleAtom) =>
      fetchFilter('companies', search, atom),
    isDisabled: (_: JobTitleAtom) => false,
  },
  {
    key: 'boards',
    name: 'Diretoria',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: JobTitleAtom) =>
      fetchFilter('boards', search, atom),
    isDisabled: (_: JobTitleAtom) => false,
  },
  {
    key: 'managements',
    name: 'Gerência',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: JobTitleAtom) =>
      fetchFilter('managements', search, atom),
    isDisabled: (_: JobTitleAtom) => false,
  },
  {
    key: 'workStations',
    name: 'Local de Trabalho',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: JobTitleAtom) =>
      fetchFilter('workStations', search, atom),
    isDisabled: (_: JobTitleAtom) => false,
  },

  // {
  //   key: 'requirementTypes',
  //   name: 'Tipo de Requisito',
  //   options: [],
  //   isMulti: true,
  //   asyncFn: (search, atom?: JobTitleAtom) =>
  //     fetchFilter('requirementTypes', search, atom),
  //   isDisabled: (_: JobTitleAtom) => false,
  // },
  // {
  //   key: 'educationalRequirements',
  //   name: 'Requisitos Educacionais',
  //   options: [],
  //   isMulti: true,
  //   asyncFn: (search, atom?: JobTitleAtom) =>
  //     fetchFilter('educationalRequirements', search, atom),
  //   isDisabled: (_: JobTitleAtom) => false,
  // },
  // {
  //   key: 'professionalRequirements',
  //   name: 'Requisitos Profissionais',
  //   options: [],
  //   isMulti: true,
  //   asyncFn: (search, atom?: JobTitleAtom) =>
  //     fetchFilter('professionalRequirements', search, atom),
  //   isDisabled: (_: JobTitleAtom) => false,
  // },
  // {
  //   key: 'otherRequirements',
  //   name: 'Outros Requisitos',
  //   options: [],
  //   isMulti: true,
  //   asyncFn: (search, atom?: JobTitleAtom) =>
  //     fetchFilter('otherRequirements', search, atom),
  //   isDisabled: (_: JobTitleAtom) => false,
  // },
];
