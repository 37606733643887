import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { Button } from '@/components/atoms/Button';
import Spinner from '@/components/atoms/Spinner';
import BasicTabs from '@/components/molecules/BasicTabs';
import { JobTitleDownloadButton } from '@/components/molecules/JobTitleDownloadButton';
import RoleModal from '@/components/molecules/RoleModal';
import SideFilter from '@/components/molecules/SideFilter';
import { filters } from '@/constants/filters/JobTitleFilter';
import api from '@/services/apiSgft';
import { jobTitleAtom } from '@/state/JobTitle.atom';
import { userIsRhOrAdmin } from '@/utils/handleSavePermissions';

import JobTitlesWrapper from './JobTitlesWrapper/JobTitlesWrapper';

const ViewJobTitles: React.FC = () => {
  const filterValues = useRecoilValue(jobTitleAtom);
  const [filteredData, setFilteredData] = useState(filterValues);
  const [isJobTitleModalOpen, setIsJobTitleModalOpen] = useState(false);
  const isRhOrAdmin = !!userIsRhOrAdmin();

  const { data: countData } = useQuery<{
    active: number;
    inCreation: number;
    inDeactivation: number;
    inactive: number;
    inEdition: number;
    open: number;
    cancelled: number;
  }>(['jobTitlesCount', filterValues], () =>
    api.get('/job-title/count').then((response) => response.data),
  );

  const handleCreateJobTitle = () => {
    if (isRhOrAdmin) {
      setIsJobTitleModalOpen(true);
    } else {
      const urlRedmine = import.meta.env.VITE_REDMINE_URL;
      const path = `${urlRedmine}/issues/new`;
      window.open(path);
    }
  };

  const handleApplyFilter = (isReseting: boolean) => {
    if (isReseting)
      setFilteredData(() => ({
        titles: '',
        seniorityLevels: [],
        companies: [],
        boards: [],
        managements: [],
        workStations: [],
      }));
    else {
      setFilteredData(() => ({
        ...filterValues,
      }));
    }
  };

  const isLoadingJobTitles = false;

  return (
    <div className="relative flex h-full w-full flex-col items-start">
      <div className="absolute -top-10 right-20 flex items-center">
        <div className="flex h-[2rem] w-[12rem] items-center justify-between rounded-lg p-4 text-[13px]">
          <Button
            className="mx-5 h-7 rounded-[1rem] text-xs font-medium"
            onClick={() => handleCreateJobTitle()}
          >
            Solicitar novo cargo
          </Button>
        </div>
        <JobTitleDownloadButton
          // TODO
          searchTerm={''}
          filteredData={filteredData}
        />
      </div>
      <SideFilter
        refetchOnChange
        filters={filters}
        atom={jobTitleAtom}
        applyChanges={handleApplyFilter}
      />

      <div className="mt-5 h-full w-[93vw] px-5">
        <RoleModal
          isOpen={isJobTitleModalOpen}
          onClose={() => setIsJobTitleModalOpen(false)}
          data={undefined}
          type={'create'}
        />
        <BasicTabs
          background="transparent"
          tabsNames={[
            'ativos',
            'em criação',
            'em desativação',
            'desativados',
            'em edição',
            'solicitações em aberto',
            'solicitações canceladas',
          ]}
          tabsCounts={[
            countData?.active ?? 0,
            countData?.inCreation ?? 0,
            countData?.inDeactivation ?? 0,
            countData?.inactive ?? 0,
            countData?.inEdition ?? 0,
            countData?.open ?? 0,
            countData?.cancelled ?? 0,
          ]}
          tabs={
            isLoadingJobTitles
              ? [
                  <div
                    className="flex h-full w-full items-center justify-center"
                    key="loading-1"
                  >
                    <Spinner size={50} />
                  </div>,
                  <div
                    className="flex h-full w-full items-center justify-center"
                    key="loading-2"
                  >
                    <Spinner size={50} />
                  </div>,
                ]
              : [
                  <div
                    className="custom-scrollbar mt-5 w-full gap-4 overflow-auto rounded-md"
                    key="active"
                  >
                    <JobTitlesWrapper jobTitleStatus="active" />
                  </div>,
                  <div
                    className="custom-scrollbar mt-5 w-full gap-4 overflow-auto rounded-md"
                    key="inCreation"
                  >
                    <JobTitlesWrapper jobTitleStatus="inCreation" />
                  </div>,
                  <div
                    className="custom-scrollbar mt-5 w-full gap-4 overflow-auto rounded-md"
                    key="inDeactivation"
                  >
                    <JobTitlesWrapper jobTitleStatus="inDeactivation" />
                  </div>,
                  <div
                    className="custom-scrollbar mt-5 w-full gap-4 overflow-auto rounded-md"
                    key="inactive"
                  >
                    <JobTitlesWrapper jobTitleStatus="inactive" />
                  </div>,
                  <div
                    className="custom-scrollbar mt-5 w-full gap-4 overflow-auto rounded-md"
                    key="inEdition"
                  >
                    <JobTitlesWrapper jobTitleStatus="inEdition" />
                  </div>,
                  <div
                    className="custom-scrollbar mt-5 w-full gap-4 overflow-auto rounded-md"
                    key="open"
                  >
                    <JobTitlesWrapper jobTitleStatus="open" />
                  </div>,
                  <div
                    className="custom-scrollbar mt-5 w-full gap-4 overflow-auto rounded-md"
                    key="cancelled"
                  >
                    <JobTitlesWrapper jobTitleStatus="cancelled" />
                  </div>,
                ]
          }
        />
      </div>
    </div>
  );
};

export default ViewJobTitles;
