import { MenuItem, Select } from '@mui/material';

interface Values {
  value: string | number;
  label: string | number;
}

interface Props {
  title: string;
  values: Values[];
  value: string | string[] | number | number[] | null;
  setState?: (value: number | string) => void;
  width?: string;
  disabled?: boolean;
  backgroundColor?: string;
  fontSize?: string;
  className?: string;
  isMulti?: boolean;
  onChange?: () => void;
  errorMessage?: string;
}

const SimpleSelect = ({
  title,
  values,
  value,
  setState,
  width,
  backgroundColor,
  disabled,
  fontSize,
  className,
  isMulti = false,
  onChange,
  errorMessage,
}: Props) => {
  if (isMulti) {
    return (
      <div className={className}>
        <div className="mb-1 text-[12px] text-[#4A4A4A]">{title}</div>
        <Select
          multiple={isMulti}
          sx={{
            width: `${width || '27rem'}`,
            height: '2rem',
            fontSize: `${fontSize || '12px'}`,
            background: backgroundColor || '',
            fontFamily: 'Graphie, sans-serif',
          }}
          value={value}
          disabled={setState === undefined || disabled}
          onChange={onChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {values.map((el) => {
            return (
              <MenuItem
                sx={{ fontSize: '12px', fontFamily: 'Graphie, sans-serif' }}
                value={el.value}
                key={el.label}
              >
                {el.label}
              </MenuItem>
            );
          })}
        </Select>
        {errorMessage && <p className="text-[10px] text-red">{errorMessage}</p>}
      </div>
    );
  } else {
    return (
      <div className={className}>
        <div className="mb-1 text-[12px] text-[#4A4A4A]">{title}</div>
        <Select
          sx={{
            width: `${width || '27rem'}`,
            height: '2rem',
            fontSize: `${fontSize || '12px'}`,
            background: backgroundColor || '',
            fontFamily: 'Graphie, sans-serif',
          }}
          value={value}
          disabled={setState === undefined || disabled}
          onChange={(e) => {
            setState && setState(e.target.value as number);
          }}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {values.map((el) => {
            return (
              <MenuItem
                sx={{ fontSize: '12px', fontFamily: 'Graphie, sans-serif' }}
                value={el.value}
                key={el.label}
              >
                {el.label}
              </MenuItem>
            );
          })}
        </Select>
        {errorMessage && <p className="text-[10px] text-red">{errorMessage}</p>}
      </div>
    );
  }
};

export default SimpleSelect;
