import { useInfiniteQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoIosSearch } from 'react-icons/io';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { useDebounce } from 'use-debounce';

import { JobTitleLine } from '@/components/atoms/JobTitleLine';
import Spinner from '@/components/atoms/Spinner';
import PrintJobTitlePDF from '@/components/organisms/PrintJobTitlePDF';
import api from '@/services/apiSgft';
import { jobTitleAtom } from '@/state/JobTitle.atom';
import { GroupedJobTitle, JobTitle } from '@/types/JobTitle';

import EmptyIcon from '../../../assets/icons/empty.svg';
import JobTitleAccordion from '../JobTitleAccordion/JobTitleAccordion';

interface JobTitlesWrapperProps {
  jobTitleStatus:
    | 'active'
    | 'inCreation'
    | 'inDeactivation'
    | 'inactive'
    | 'inEdition'
    | 'open'
    | 'cancelled';
}

function JobTitlesWrapper({ jobTitleStatus }: JobTitlesWrapperProps) {
  const filterValues = useRecoilValue(jobTitleAtom);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermDebounced] = useDebounce(searchTerm, 500);

  const observerRef = useRef<HTMLDivElement | null>(null); // Ref para o observer do último elemento

  const printRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

  const handleOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const fetchJobTitles = async ({
    pageParam = 1,
  }): Promise<{
    data: GroupedJobTitle[];
    nextPage: number | undefined;
    hasMore: boolean;
  }> => {
    try {
      const response = await api.get('/job-title/grouped', {
        params: {
          title: searchTerm,
          seniorityLevels: filterValues?.seniorityLevels,
          companies: filterValues?.companies,
          boards: filterValues?.boards,
          managements: filterValues?.managements,
          workStations: filterValues?.workStations,
          status: jobTitleStatus,
          page: pageParam,
        },
      });

      const objResults = response.data;
      const hasMore = objResults.length > 0;

      return {
        data: objResults,
        nextPage: hasMore ? pageParam + 1 : undefined,
        hasMore,
      };
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      ['jobTitle', filterValues, searchTermDebounced, jobTitleStatus],
      fetchJobTitles,
      {
        getNextPageParam: (lastPage) => lastPage.nextPage,
      },
    );

  const groupedJobTitles = data?.pages.flatMap((page) => page.data) || [];
  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    [hasNextPage, isFetchingNextPage, fetchNextPage],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: '100px',
      threshold: 1.0,
    });
    if (observerRef.current) observer.observe(observerRef.current);
    return () => observer.disconnect();
  }, [observerCallback]);

  return (
    <div>
      <div className="my-2 mb-1 flex w-full justify-end bg-white p-2">
        <div className="flex h-[2rem] w-[20rem] items-center justify-between rounded-lg bg-background p-4 text-[13px] ">
          <input
            className="h-[2rem] w-[20rem] rounded-lg bg-background p-4 text-[13px] focus:outline-none "
            type="text"
            placeholder="Pesquisar por cargo"
            value={searchTerm}
            onChange={handleOnchange}
          />
          <IoIosSearch className="cursor-auto" color="#193db8" size={'18px'} />
        </div>
      </div>

      {groupedJobTitles && groupedJobTitles.length > 0 ? (
        <div className="mt-[10px] flex h-full flex-col items-start gap-[10px]">
          {groupedJobTitles.map((groupedJobTitle) => (
            <JobTitleAccordion
              title={groupedJobTitle.title}
              key={groupedJobTitle.id}
              groupedJobTitle={groupedJobTitle}
            >
              {groupedJobTitle.jobTitles.map((jobTitle: JobTitle) => {
                return (
                  <div key={jobTitle.id} className="relative w-full">
                    <JobTitleLine jobTitle={jobTitle} />
                    <div className="hidden">
                      <PrintJobTitlePDF
                        ref={(el) => {
                          printRefs.current[jobTitle.id] = el;
                        }}
                        jobTitle={jobTitle}
                      />
                    </div>
                  </div>
                );
              })}
            </JobTitleAccordion>
          ))}

          <div ref={observerRef} className="block h-2 w-full ">
            &nbsp;
          </div>
          {isLoading && (
            <div className="flex w-full justify-center p-4">
              <Spinner />
            </div>
          )}
        </div>
      ) : (
        <div className="mt-[10px] flex w-full flex-col items-center justify-center rounded-md bg-white pb-8">
          <img src={EmptyIcon} className="w-40" />
          <div className="w-full text-center text-sm">
            <p>Nenhum cargo cadastrado com esses parâmetros</p>
            <span className="font-semibold text-blue-800">
              Cadastre um ou altere os valores dos filtros para visualizar os
              cargos!
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default JobTitlesWrapper;
