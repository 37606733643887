import { JobTitle } from '@/types/JobTitle';
import { getFirstAndLastName } from '@/utils/getFirstAndLastName';

interface SectionContent {
  title?: string;
  subtitle?: string;
  content: any;
  minHeight: string;
  containerClass: string;
  headerClass: string;
  contentClass: string;
  subtitleClassname?: string;
}

interface Section {
  sectionsPerRow: 1 | 2;
  content: SectionContent[];
}

const baseContainerClass =
  'bg-white border-l border-r border-b border-gray-300 pb-0';
const baseHeaderClass =
  'bg-[#EDF0FA] flex items-center mb-1 px-4 py-1 text-[#193CB9] text-[16px] font-semibold';
const baseContentClass = 'text-gray-700 p-4 text-[12px]';

export const getJobInfo = async (jobTitle: JobTitle) => {
  const functions: string[] = [jobTitle.responsibilities];

  const workExperience: string[] = [jobTitle.experience];

  const skills: string[] = [jobTitle.skillsAndCompetencies];

  const boards = jobTitle.generalInformationJobTitle.managements.map(
    (generalInformationJobTitleDirection) =>
      generalInformationJobTitleDirection.management.board?.name?.toUpperCase(),
  );

  const managements = jobTitle.generalInformationJobTitle.managements.map(
    (generalInformationJobTitleManagement) =>
      generalInformationJobTitleManagement.management?.name?.toUpperCase(),
  );

  const companies = jobTitle.generalInformationJobTitle.managements.map(
    (generalInformationJobTitleCompany) =>
      generalInformationJobTitleCompany.management.board.company?.name?.toUpperCase(),
  );
  const { mandatory: mandatoryRequirements, optional: optionalRequirements } =
    jobTitle.formattedRequirements;
  const RoleExtractionFirstPage: Section[] = [
    {
      sectionsPerRow: 1,
      content: [
        {
          title: '',
          content: (
            <div className="grid h-full grid-cols-[1fr,1fr,1fr,1fr] gap-0 rounded p-2">
              <div>Versão</div>
              <div>Criador</div>
              <div>Revisor</div>
              <div>Aprovador</div>

              <div>{jobTitle.generalInformationJobTitle?.version ?? ''}</div>
              <div className="max-w-40 overflow-hidden break-words">
                {jobTitle.creator ? getFirstAndLastName(jobTitle.creator) : ''}
              </div>
              <div className="max-w-40 overflow-hidden break-words">
                {jobTitle.revisor ? getFirstAndLastName(jobTitle.revisor) : ''}
              </div>
              <div className="max-w-40 overflow-hidden break-words">
                {jobTitle.approver
                  ? getFirstAndLastName(jobTitle.approver)
                  : ''}
              </div>
            </div>
          ),
          minHeight: '',
          containerClass: `${baseContainerClass} mb-8 border-t`,
          headerClass: baseHeaderClass,
          contentClass: baseContentClass,
        },
      ],
    },
    {
      sectionsPerRow: 2,
      content: [
        {
          title: 'Título do Cargo',
          content: `${jobTitle.generalInformationJobTitle?.name?.toUpperCase() ?? ''}`,
          minHeight: '',
          containerClass: baseContainerClass,
          headerClass: baseHeaderClass,
          contentClass: baseContentClass,
        },
        {
          title: 'Nível',
          content: `${jobTitle.seniorityLevel?.name?.toUpperCase() ?? ''}`,
          minHeight: '7vh',
          containerClass: baseContainerClass,
          headerClass: baseHeaderClass,
          contentClass: baseContentClass,
        },
      ],
    },
    {
      sectionsPerRow: 2,
      content: [
        {
          title: 'Empresa',
          content: `${[...new Set(companies)].join(', ') ?? ''}`,
          minHeight: '7vh',
          containerClass: baseContainerClass,
          headerClass: baseHeaderClass,
          contentClass: baseContentClass,
        },
        {
          title: 'Diretoria',
          content: `${[...new Set(boards)].join(', ') ?? ''}`,
          minHeight: '7vh',
          containerClass: baseContainerClass,
          headerClass: baseHeaderClass,
          contentClass: baseContentClass,
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          title: 'Gerência',
          content: `${[...new Set(managements)].join(', ') ?? ''}`,
          minHeight: '7vh',
          containerClass: baseContainerClass,
          headerClass: baseHeaderClass,
          contentClass: baseContentClass,
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          title: 'GHE por Local de Trabalho',
          content: (
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="w-1/2 border border-gray-300 px-4 py-2 text-center">
                    Local de Trabalho
                  </th>
                  <th className="w-1/2 border border-gray-300 px-4 py-2 text-center">
                    GHE
                  </th>
                </tr>
              </thead>
              <tbody>
                {jobTitle.generalInformationJobTitle.workStations.map(
                  (ghePerWorkStation, index) => (
                    <tr key={index}>
                      <td className="border border-gray-300 px-4 py-2 ">
                        {ghePerWorkStation.workStation?.name}
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-center">
                        {ghePerWorkStation.gheCode ?? 'N/A'}
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          ),
          minHeight: '7vh',
          containerClass: `${baseContainerClass}`,
          headerClass: baseHeaderClass,
          contentClass: baseContentClass,
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          title: 'Objetivo do Cargo',
          content: `${jobTitle.objective ?? ''}`,
          minHeight: '1vh',
          containerClass: `${baseContainerClass}`,
          headerClass: baseHeaderClass,
          contentClass: `${baseContentClass} text-justify whitespace-pre-wrap`,
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          title: 'Funções e Responsabilidades',
          content:
            functions.length > 0 ? (
              <div>
                {functions.map((item: string, index: number) => (
                  <div key={index}>{item}</div>
                ))}
              </div>
            ) : null,
          minHeight: '1vh',
          containerClass: baseContainerClass,
          headerClass: baseHeaderClass,
          contentClass: `${baseContentClass} text-justify whitespace-pre-wrap`,
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          title: 'Requisitos do Cargo',
          subtitle: 'Requisitos Educacionais Obrigatórios',
          content:
            mandatoryRequirements.educational.length > 0 ? (
              <div>
                {mandatoryRequirements.educational.map(
                  (item: string, index: number) => (
                    <div key={index}>{item}</div>
                  ),
                )}
              </div>
            ) : null,
          minHeight: '1vh',
          containerClass: `${baseContainerClass} border-t mb-0.5`,
          headerClass: baseHeaderClass,
          contentClass: `${baseContentClass} pl-6 `,
          subtitleClassname: 'pl-6 text-gray-600 text-[22px]',
        },
      ],
    },
    // second page
    {
      sectionsPerRow: 1,
      content: [
        {
          subtitle: 'Requisitos Educacionais Desejáveis',
          content:
            optionalRequirements.educational.length > 0 ? (
              <div>
                {optionalRequirements.educational.map(
                  (item: string, index: number) => (
                    <div key={index}>{item}</div>
                  ),
                )}
              </div>
            ) : null,
          minHeight: '1vh',
          containerClass: `${baseContainerClass} border-t mb-0.5`,
          headerClass: baseHeaderClass,
          contentClass: `${baseContentClass} pl-6 `,
          subtitleClassname: 'pl-6 text-gray-600 text-[22px]',
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          subtitle: 'Requisitos Profissionais Obrigatórios',
          content:
            mandatoryRequirements.professional.length > 0 ? (
              <div>
                {mandatoryRequirements.professional.map(
                  (item: string, index: number) => (
                    <div key={index}>{item}</div>
                  ),
                )}
              </div>
            ) : null,
          minHeight: '1vh',
          containerClass: `${baseContainerClass} border-t mb-0.5`,
          headerClass: baseHeaderClass,
          contentClass: `${baseContentClass} pl-6 `,
          subtitleClassname: 'pl-6 text-gray-600 text-[22px]',
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          subtitle: 'Requisitos Profissionais Desejáveis',
          content:
            optionalRequirements.professional.length > 0 ? (
              <div>
                {optionalRequirements.professional.map(
                  (item: string, index: number) => (
                    <div key={index}>{item}</div>
                  ),
                )}
              </div>
            ) : null,
          minHeight: '1vh',
          containerClass: `${baseContainerClass} border-t mb-0.5`,
          headerClass: baseHeaderClass,
          contentClass: `${baseContentClass} pl-6 `,
          subtitleClassname: 'pl-6 text-gray-600 text-[22px]',
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          subtitle: 'Outros Requisitos Obrigatórios',
          content:
            mandatoryRequirements.other.length > 0 ? (
              <div>
                {mandatoryRequirements.other.map(
                  (item: string, index: number) => (
                    <div key={index}>{item}</div>
                  ),
                )}
              </div>
            ) : null,
          minHeight: '1vh',
          containerClass: `${baseContainerClass} border-t mb-0.5`,
          headerClass: baseHeaderClass,
          contentClass: `${baseContentClass} pl-6 `,
          subtitleClassname: 'pl-6 text-gray-600 text-[22px]',
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          subtitle: 'Outros Requisitos Desejáveis',
          content:
            optionalRequirements.other.length > 0 ? (
              <div>
                {optionalRequirements.other.map(
                  (item: string, index: number) => (
                    <div key={index}>{item}</div>
                  ),
                )}
              </div>
            ) : null,
          minHeight: '1vh',
          containerClass: `${baseContainerClass} border-t mb-8 mb-0.5`,
          headerClass: baseHeaderClass,
          contentClass: `${baseContentClass} pl-6 `,
          subtitleClassname: 'pl-6 text-gray-600 text-[22px]',
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          title: 'Experiências',
          content:
            workExperience.length > 0 ? (
              <div>
                {workExperience.map((item: string, index: number) => (
                  <div key={index}>{item}</div>
                ))}
              </div>
            ) : null,
          minHeight: '1vh',
          containerClass: baseContainerClass,
          headerClass: baseHeaderClass,
          contentClass: `${baseContentClass} text-justify whitespace-pre-wrap`,
        },
      ],
    },
    // third page
    {
      sectionsPerRow: 1,
      content: [
        {
          title: 'Habilidades e Competências',
          content:
            skills.length > 0 ? (
              <div>
                {skills.map((item: string, index: number) => (
                  <div key={index}>{item}</div>
                ))}
              </div>
            ) : null,
          minHeight: '1vh',
          containerClass: baseContainerClass,
          headerClass: baseHeaderClass,
          contentClass: `${baseContentClass} text-justify whitespace-pre-wrap`,
        },
      ],
    },
  ];

  const filteredRoleExtractionFirstPage = RoleExtractionFirstPage.filter(
    (section) => !section.content.every((item) => !item.content),
  );

  return {
    RoleExtractionFirstPage: filteredRoleExtractionFirstPage,
  };
};
