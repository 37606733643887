/* eslint-disable no-unused-vars */
export interface SelectOption {
  value: string;
  label: string;
}

export const locationOptions: SelectOption[] = [
  { value: 'Alagoas', label: 'Alagoas' },
  { value: 'Tucano Sul', label: 'Tucano Sul' },
  { value: 'Matriz', label: 'Matriz' },
  { value: 'TAMAC', label: 'Terminal Aquaviáro de Maceió (TAMAC)' },
];

export enum StatusTrainingControl {
  NaoAplicavel = 'Não aplicável',
  AguardandoLiberacao = 'Aguardando liberação',
  Liberado = 'Liberado',
  Planejado = 'Planejado',
  AutorizadoPeloGestor = 'Autorizado pelo gestor',
  NaoAutorizadoPeloGestor = 'Não Autorizado pelo gestor',
  Confirmado = 'Confirmado',
  NaoPlanejado = 'Não planejado',
  Concluido = 'Concluído',
  Finalizado = 'Finalizado',
  Convalidado = 'Convalidado',
  Invalidado = 'Invalidado',
  Reprovado = 'Reprovado',
  NaoRealizado = 'Não realizado',
  ConcluidoPorEquivalencia = 'Concluído por equivalencia',
  ConvocacaoRejeitada = 'Convocação Rejeitada',
}

export enum StatusTrainingControlHistory {
  Concluido = 'Concluído',
  Reprovado = 'Reprovado',
  NaoRealizado = 'Não realizado',
  Convalidado = 'Convalidado',
  Invalidado = 'Invalidado',
  NaoAplicavel = 'Não aplicável',
  Finalizado = 'Finalizado',
  ConcluidoPorEquivalencia = 'Concluído por equivalencia',
}

export const historyStatusOptions: SelectOption[] = [
  StatusTrainingControl.Concluido,
  StatusTrainingControl.Reprovado,
  StatusTrainingControl.NaoRealizado,
  StatusTrainingControl.Convalidado,
  StatusTrainingControl.Invalidado,
  StatusTrainingControl.NaoAplicavel,
].map((status) => ({
  value: status,
  label: status,
}));

export const greenStatus = ['Válido'];

export const yellowStatus = ['Vence em 3 meses', 'Vence em 1 mês'];

export const redStatus = ['Vencido', 'Inválido', 'Pendente'];

export const eadAllowedStatuses = [
  StatusTrainingControl.NaoAplicavel,
  StatusTrainingControl.AguardandoLiberacao,
  StatusTrainingControl.Concluido,
  StatusTrainingControl.Reprovado,
  StatusTrainingControl.NaoRealizado,
  StatusTrainingControl.Convalidado,
  StatusTrainingControl.Invalidado,
  StatusTrainingControl.ConvocacaoRejeitada,
].map((status) => ({
  label: status,
  value: status,
}));

export const presencialAllowedStatuses = [
  StatusTrainingControl.NaoAplicavel,
  StatusTrainingControl.NaoPlanejado,
  StatusTrainingControl.Concluido,
  StatusTrainingControl.Reprovado,
  StatusTrainingControl.NaoRealizado,
  StatusTrainingControl.Convalidado,
  StatusTrainingControl.Invalidado,
  StatusTrainingControl.ConvocacaoRejeitada,
].map((status) => ({
  label: status,
  value: status,
}));

export const ValidStatusesForInvalidStatus = [
  StatusTrainingControl.Concluido,
  StatusTrainingControl.Convalidado,
  StatusTrainingControl.Finalizado,
];

export const ValidStatusesForUploadEvidences = [
  StatusTrainingControl.Concluido,
  StatusTrainingControl.Convalidado,
  StatusTrainingControl.Invalidado,
  StatusTrainingControl.Finalizado,
  StatusTrainingControl.ConcluidoPorEquivalencia,
];

export const ValidStatusToEnableCompletionDateForTrainingFileTeamEmployees = [
  StatusTrainingControl.NaoAplicavel,
  StatusTrainingControl.Concluido,
  StatusTrainingControl.Reprovado,
  StatusTrainingControl.NaoRealizado,
  StatusTrainingControl.Convalidado,
  StatusTrainingControl.Invalidado,
  StatusTrainingControl.ConvocacaoRejeitada,
];

export enum Modality {
  Online = 'Online',
  Presencial = 'Presencial',
  Semipresencial = 'Semipresencial',
  SomenteLeitura = 'Somente Leitura',
}

export enum PartModality {
  Online = 'Online',
  Presencial = 'Presencial',
}

export enum OnlineTrainingType {
  Sync = 'Síncrono',
  Async = 'Assíncrono',
}
export enum ProcedureModality {
  Online = 'Online',
  Presencial = 'Presencial',
  SomenteLeitura = 'Somente Leitura',
}

export const modalityOptions: SelectOption[] = [
  Modality.Online,
  Modality.Presencial,
  Modality.Semipresencial,
  Modality.SomenteLeitura,
].map((modality) => ({
  label: modality,
  value: modality,
}));

export const regulatoryModalityOptions: SelectOption[] = [
  { value: Modality.Online, label: 'Online' },
  { value: Modality.Semipresencial, label: 'Semipresencial' },
  { value: Modality.Presencial, label: 'Presencial' },
];

export const procedureModalityOptions: SelectOption[] = [
  { value: ProcedureModality.Online, label: 'Online' },
  { value: ProcedureModality.Presencial, label: 'Presencial' },
  { value: ProcedureModality.SomenteLeitura, label: 'Somente Leitura' },
];

export const partModalityOptions: SelectOption[] = [
  PartModality.Online,
  PartModality.Presencial,
].map((partModality) => ({
  label: partModality,
  value: partModality,
}));
