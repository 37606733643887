import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@radix-ui/react-tooltip';

import { statusColumns } from '@/constants/tableColumns/StatusTableColumns';
import { StatusTrainingData } from '@/types/StatusTrainingData';
import { cn } from '@/utils/cn';

import TableData from '../atoms/TableData';
import Table from '../organisms/Table';

interface StatusTableCellProps {
  statusData: StatusTrainingData;
}

export const StatusTableCell = ({ statusData }: StatusTableCellProps) => {
  const uniqueStatuses = Array.from(new Set(statusData.map((it) => it.status)));

  const statusText = uniqueStatuses.join('/');

  return (
    <TooltipProvider skipDelayDuration={100} delayDuration={200}>
      <Tooltip>
        <TooltipTrigger type="button">
          <TableData
            tableValue={statusText}
            className={cn(
              'flex h-6 w-28 items-center rounded-sm px-3 text-left ',
              uniqueStatuses.length > 1 ? 'cursor-pointer' : 'cursor-auto',
            )}
          />
        </TooltipTrigger>
        {uniqueStatuses.length > 1 && (
          <TooltipContent className="z-[1000]">
            <TooltipArrow />
            <div className="z-[1000] rounded-md border-2 bg-white p-4 shadow-md">
              <div className="w-full text-start text-lg font-semibold text-[#193CB9]">
                Disponibilidade
              </div>
              <Table
                columns={statusColumns}
                data={statusData}
                containerClassname="text-center w-full"
                trBodyClassname="even:bg-white"
              />
            </div>
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
};
