import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import React, { useState } from 'react';

import EditButton from './EditButton';
import TableCellText from './TableCellText';

interface Props {
  title?: string;
  value: number | string;
  disabled?: boolean;
  setState: (value: any) => void;
  handleSubmit: () => void;
}

const EditableTableInputText = ({
  title,
  value,
  disabled,
  setState,
  handleSubmit,
}: Props) => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  return (
    <>
      <div className="h-inherit flex w-full items-center">
        <TableCellText text={value.toString()} className="mr-auto" />
        <EditButton onClick={handleClick} disabled={!!disabled} />
      </div>
      <BasePopup open={Boolean(anchor)} anchor={anchor}>
        <div className="flex flex-col rounded bg-white p-5 shadow-md">
          <div className="flex flex-col">
            {title ? (
              <label className="text-center text-sm font-medium text-primary">
                {title}
              </label>
            ) : null}

            <input
              required={false}
              className="mt-2 h-8 w-[260px] rounded-md border border-[#D8D8D8] px-3 font-graphie text-[12px] font-normal text-[#4A4A4A] active:border-[#cbcbcb]"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
              onChange={(e) => {
                setState(e.target.value);
              }}
              defaultValue={value}
            />
          </div>
          <section className="mt-5 flex">
            <button
              className="rounded-full border border-primary px-5 py-1 text-primary"
              onClick={handleClick}
            >
              Cancelar
            </button>
            <button
              className="ml-auto rounded-full bg-primary px-[44px] py-1 text-white"
              onClick={() => {
                handleSubmit();
              }}
            >
              Salvar
            </button>
          </section>
        </div>
      </BasePopup>
    </>
  );
};

export default EditableTableInputText;
