import { forwardRef, useEffect, useState } from 'react';

import { getJobInfo } from '@/constants/SectionsRoleExtraction';
import { JobTitle } from '@/types/JobTitle';

import InfoLayout from './InfoLayout';

interface SectionContent {
  title?: string;
  subtitle?: string;
  content: any;
  minHeight: string;
  containerClass: string;
  headerClass: string;
  contentClass: string;
}

interface Section {
  sectionsPerRow: 1 | 2;
  content: SectionContent[];
}

interface PrintJobTitlePDFProps {
  jobTitle: JobTitle;
}

const PrintJobTitlePDF = forwardRef<HTMLDivElement, PrintJobTitlePDFProps>(
  ({ jobTitle }, ref) => {
    const [jobTitlePDFSections, setJobTitlePDFSections] = useState<Section[]>(
      [],
    );

    useEffect(() => {
      const fetchData = async () => {
        try {
          const data = await getJobInfo(jobTitle);

          setJobTitlePDFSections(data.RoleExtractionFirstPage);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }, [jobTitle]);

    return (
      <div ref={ref} style={{ width: '100%' }} className="print-roles portrait">
        <InfoLayout
          title="Descrição do Cargo"
          headerClass="bg-[#EDF0FA] text-gray-700 font-semibold px-4 mb-2 h-[72px] flex items-center justify-between text-[20px]"
          sections={jobTitlePDFSections}
        />
      </div>
    );
  },
);

PrintJobTitlePDF.displayName = 'PrintJobTitlePDF';
export default PrintJobTitlePDF;
