import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';

import Spinner from '@/components/atoms/Spinner';
import NewSideFilter from '@/components/molecules/NewSideFilter';
import { filters } from '@/constants/filters/NewScheduleFilter';
import { columns } from '@/constants/tableColumns/ViewScheduleTableColumns';
import {
  TeamStructureFilterOptionsSchema,
  TeamStructureFiltersOptions,
} from '@/constants/teamStructureConstants';
import { trackEvent } from '@/utils/trackEvent';

import EmptyIcon from '../../assets/icons/empty.svg';
import { Button } from '../../components/atoms/Button';
import { ConfirmationDialog } from '../../components/atoms/ConfirmationDialog';
import { DownloadButton } from '../../components/atoms/DownloadButton';
import EditButton from '../../components/atoms/EditButton';
import { InconsistencyExtractionButton } from '../../components/atoms/InconsistencyExtractionButton';
import TrashButton from '../../components/atoms/TrashButton';
import ScheduleSubtitle from '../../components/molecules/ScheduleSubtitle';
import ViewScheduleWrapper from '../../components/molecules/ViewScheduleWrapper';
import Schedule from '../../components/organisms/Schedule';
import Table from '../../components/organisms/Table';
import { SCALE_MEDICO } from '../../constants/TeamRegister';
import api from '../../services/apiSgft';
import { teamRegisterAtom } from '../../state/TeamRegister.atom';
import { viewScheduleFiltersAtom } from '../../state/ViewScheduleFilter.atom';
import { TeamStructure } from '../../types/ViewSchedule';
import {
  isReadOnlyUser,
  userCanSeeTeamStructureForms,
} from '../../utils/handleSavePermissions';

const ViewSchedule: React.FC = () => {
  const { ref, inView } = useInView();
  const queryClient = useQueryClient();
  const filteredData = useRecoilValue(viewScheduleFiltersAtom);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [deleteStructure, setDeleteStructure] = useState<TeamStructure>();
  const [, setTeamStructure] = useRecoilState(teamRegisterAtom);
  const navigate = useNavigate();

  const fetchStructures = async ({ pageParam = 1 }) => {
    if (!filteredData.timeRange.startDate || !filteredData.timeRange.endDate) {
      return [];
    }
    try {
      const response = await api.get('/team-structure/schedules', {
        params: {
          size: 4,
          page: pageParam,
          startDate: filteredData.timeRange.startDate,
          endDate: filteredData.timeRange.endDate,
          workStation: filteredData.workplace,
          management: filteredData.management,
          scale: filteredData.schedule,
          sector: filteredData.sector,
          area: filteredData.area,
          employee: filteredData.employee,
          teamStructure: filteredData.teamStructure,
        },
      });
      response.data.structures = response.data.structures.map(
        (object: any) => ({
          ...object,
          schedule_type:
            object.schedule_type === SCALE_MEDICO[0].value
              ? SCALE_MEDICO[0].label
              : object.schedule_type,
        }),
      );
      return response.data;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const editTeamStructure = (structure: TeamStructure) => {
    setTeamStructure({
      id: structure.id,
      name: structure.name,
      management: structure.managementId,
      area: structure.areaId,
      otherArea: '',
      sector: structure.sector,
      otherSector: '',
      workstation: structure.work_stationId,
      structureType: '',
      scale: '',
      teamsLength: 0,
      onNotice: structure.on_notice ? 'SIM' : 'NÃO',
      mandatoryOnNotice: structure.mandatory_on_notice ? 'SIM' : 'NÃO',
      confinement: structure.confinement ? 'SIM' : 'NÃO',
    });
    navigate('editar-estrutura-de-turma', {
      state: { structureId: structure.id },
    });
  };

  const deleteTeamStructure = async (id: number) => {
    try {
      await api.delete(`/team-structure/${id}`);
      toast.success('Estrutura de turma deletada com sucesso!', {
        theme: 'colored',
        toastId: 'success',
      });
      const deletedStructure = structures?.find((s) => s.id === id);
      trackEvent(
        deletedStructure?.management || '',
        'delete_team_structure',
        deletedStructure?.sector || '',
      );
      setConfirmationModalOpen(false);
      queryClient.invalidateQueries(['team-structure', filteredData]);
    } catch (e) {
      if (
        e instanceof AxiosError &&
        typeof e.response?.data.message !== 'undefined'
      ) {
        toast.error(e.response.data.message, {
          theme: 'colored',
          toastId: 'error',
        });
      } else {
        toast.error('Erro ao deletar estrutura de turma!', {
          theme: 'colored',
          toastId: 'error',
        });
      }
      throw e;
    }
  };

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(['team-structure', filteredData], fetchStructures, {
    retry: false,
    getNextPageParam: (actualPage) => {
      return actualPage.nextPage;
    },
  });
  const structures = data?.pages.flatMap((page) => page.structures) ?? [];

  const fetchFilterOptions = async () => {
    try {
      const response = await api.get('team-structure/filters', {
        params: {
          workStation: filteredData.workplace,
          management: filteredData.management,
          scale: filteredData.schedule,
          sector: filteredData.sector,
          area: filteredData.area,
          employee: filteredData.employee,
          teamStructure: filteredData.teamStructure,
        },
      });
      const parsedData = TeamStructureFilterOptionsSchema.parse(response.data);
      return parsedData;
    } catch (error) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw error;
    }
  };

  const { data: filterOptions, isLoading: isLoadingFilters } =
    useQuery<TeamStructureFiltersOptions>(
      ['team-structure-filters', filteredData],
      fetchFilterOptions,
    );

  const disableActionButton = isReadOnlyUser();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  return (
    <div className="custom-scrollbar relative flex h-full max-h-screen w-full flex-col items-start overflow-y-scroll">
      <div className="fixed right-20 top-2 z-10 flex items-center gap-2">
        <Button
          className="h-7 rounded-[1rem] text-xs font-medium"
          displayValue={userCanSeeTeamStructureForms() ? undefined : 'none'}
          onClick={() => navigate('cadastrar-estrutura-de-turma')}
          disabled={disableActionButton}
        >
          Criar Estrutura de Turma
        </Button>
        <ScheduleSubtitle />
        <DownloadButton filteredData={filteredData} />
        <InconsistencyExtractionButton />
      </div>
      <NewSideFilter
        filters={filters}
        atom={viewScheduleFiltersAtom}
        options={filterOptions}
        isLoading={isLoadingFilters}
      />

      <ViewScheduleWrapper isError={isError} isLoading={isLoading}>
        {structures?.length > 0 ? (
          structures?.map((structure) => (
            <div
              className="flex w-full flex-col rounded-md bg-white"
              key={`team-structure-${structure?.id}`}
            >
              <div className="flex justify-between">
                <div className="flex items-center gap-4 p-4 text-xs text-blue-800">
                  <div className="whitespace-nowrap rounded-lg bg-blue-100 px-4 py-2">
                    <p>
                      Estrutura de Turma:{' '}
                      <span className="font-bold">{structure?.name}</span>
                    </p>
                  </div>
                  <div className="flex flex-wrap gap-3">
                    <div>
                      <p>
                        Local de Trabalho:{' '}
                        <span className="font-bold">
                          {structure?.work_station}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Gerência:{' '}
                        <span className="font-bold">
                          {structure?.management}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Área:{' '}
                        <span className="font-bold">{structure?.area}</span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Setor:{' '}
                        <span className="font-bold">{structure?.sector}</span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Escala:{' '}
                        <span className="font-bold">
                          {structure?.schedule_type}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Sobreaviso:{' '}
                        <span className="font-bold">
                          {structure?.on_notice ? 'SIM' : 'NÃO'}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mr-4 flex p-4">
                  <EditButton
                    id={`button-edit-${structure?.id}`}
                    color="primary"
                    displayValue={
                      userCanSeeTeamStructureForms() ? undefined : 'none'
                    }
                    onClick={() => editTeamStructure(structure)}
                    disabled={disableActionButton}
                  />
                  {confirmationModalOpen && deleteStructure && (
                    <ConfirmationDialog
                      title={`Tem certeza de que deseja deletar a estrutura ${deleteStructure.name}?`}
                      description={
                        'Cuidado! Esta ação não poderá ser desfeita!'
                      }
                      setIsOpen={setConfirmationModalOpen}
                      onConfirmation={() =>
                        deleteTeamStructure(deleteStructure.id)
                      }
                    />
                  )}
                  <TrashButton
                    id={`button-delete-${structure?.id}`}
                    disabled={disableActionButton}
                    color="primary"
                    displayValue={
                      userCanSeeTeamStructureForms() ? undefined : 'none'
                    }
                    onClick={() => {
                      setConfirmationModalOpen(true);
                      setDeleteStructure(structure);
                    }}
                  />
                </div>
              </div>
              <div>
                {structure?.employees.length ? (
                  <div className="relative">
                    <div
                      className={`custom-scrollbar flex max-h-[33.5rem] w-full justify-center overflow-y-scroll px-3`}
                    >
                      <Table
                        data={structure?.employees}
                        columns={columns}
                        columnVisibility={{
                          id: false,
                          employee_number: false,
                        }}
                        stickyHeader={structure?.employees.length >= 10}
                      />
                      <Schedule
                        data={structure?.employees}
                        startDate={parseISO(filteredData.timeRange.startDate!)}
                        endDate={parseISO(filteredData.timeRange.endDate!)}
                        stickyHeader={structure?.employees.length >= 10}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="flex w-full flex-col items-center justify-center py-10">
                    <img src={EmptyIcon} className="w-40" />
                    <div className="flex flex-col items-center text-sm">
                      <p>
                        Não existe nenhum colaborador cadastrado nessa estrutura
                        de turma
                      </p>
                      <span className="font-semibold text-blue-800">
                        Cadastre um para visualizar a escala!
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="flex h-[89vh] w-full flex-col items-center justify-center rounded-md bg-white">
            <img src={EmptyIcon} className="w-40" />
            <div className="w-full text-center text-sm">
              <p>Nenhuma estrutura de turma encontrada com esses parâmetros</p>
              <span className="font-semibold text-blue-800">
                Cadastre uma ou altere os valores dos filtros para visualizar a
                escala!
              </span>
            </div>
          </div>
        )}
        {hasNextPage && (
          <div className="flex min-h-8 w-full justify-center" ref={ref}>
            {isFetchingNextPage && <Spinner size={30} />}
          </div>
        )}
      </ViewScheduleWrapper>
    </div>
  );
};

export default ViewSchedule;
